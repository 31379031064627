import $ from 'jquery';
import debounce from 'just-debounce';
import Flickity from 'flickity';
import { classes, selectors } from '../Constants';

export default class ProductSlideshow {
  constructor(options = {}) {
    this.el = options.el || null;

    if (!this.el) {
      return console.warn('Unable to initiate ProductSlideshow.js');
    }

    this.flickity = null;
    this.flickitySelect = this._flickitySelect.bind(this);
    this.flickityStaticClick = this._flickityStaticClick.bind(this);
    this.flickityPrev = () => this.flickity.previous();
    this.flickityNext = () => this.flickity.next();

    this.staticGallery = false;

    this.onThumbnailClick = this._onThumbnailClick.bind(this);
    this.onThumbnailKeyPress = this._onThumbnailKeyPress.bind(this);

    this.$thumbnailsWrapper = $(selectors.product.slideshowThumbnailsWrapper, this.el);

    this.imageContainer = this.el.querySelector(selectors.product.slideshowImages);
    this.thumbnailContainer = this.el.querySelector(selectors.product.slideshowThumbnails);

    this.$images = $(selectors.product.slideshowImage, this.el);
    this.$thumbnails = $(selectors.product.slideshowThumbnail, this.el);

    this.thumbnailPrev = this.el.querySelector(selectors.product.slideshowThumbnailsPrev);
    this.thumbnailNext = this.el.querySelector(selectors.product.slideshowThumbnailsNext);

    this.$selectedThumbnail = $(selectors.product.slideshowThumbnailSelected, this.thumbnailContainer);
    this.selectedThumbnailIndex = this.$thumbnails.index(this.$selectedThumbnail);

    this.onResize = debounce(this._onResize, 15).bind(this);

    this._init();
  }

  switchImage(id) {
    const $image = $(`[data-product-slideshow-image="${id}"]`, this.el);
    const $thumbnail = $(`[data-product-slideshow-thumbnail="${id}"]`, this.el);

    this.$images.removeClass(classes.product.slideshowImageSelected);
    this.$thumbnails.removeClass(classes.product.slideshowThumbnailSelected);

    $image.addClass(classes.product.slideshowImageSelected);
    $thumbnail.addClass(classes.product.slideshowThumbnailSelected);

    this.$selectedThumbnail = $thumbnail;
    this.selectedThumbnailIndex = this.$thumbnails.index(this.$selectedThumbnail);
  }

  destroy() {
    window.removeEventListener('resize', this.onResize);
    this._destroyFlickity();
    this._destroyStaticGallery();
  }

  _init() {
    if (this._shouldInitFlickity()) {
      this._initFlickity();
    } else if (this._shouldInitStaticGallery()) {
      this._initStaticGallery();
    }

    window.addEventListener('resize', this.onResize);
  }

  _onResize() {
    if (this._shouldInitFlickity()) {
      if (this.staticGallery) {
        this._destroyStaticGallery();
      }

      this._initFlickity();
      this.flickity.select(this.selectedThumbnailIndex);
    } else if (this._shouldInitStaticGallery()) {
      if (this.flickity) {
        this._destroyFlickity();
      }

      this._initStaticGallery();
      this.$selectedThumbnail.trigger('click');
    }
  }

  _shouldInitStaticGallery() {
    return !!this.thumbnailContainer;
  }

  _shouldInitFlickity() {
    if (!this.thumbnailContainer) {
      return false;
    }

    const containerWidth = this.thumbnailContainer.getBoundingClientRect().width;

    let thumbnailsWidth = 0;

    this.$thumbnails.each((index, el) => {
      thumbnailsWidth += $(el).outerWidth(true);
    });

    return thumbnailsWidth > containerWidth;
  }

  _initFlickity() {
    if (this.flickity) {
      return;
    }

    this.$selectedThumbnail = $(selectors.product.slideshowThumbnailSelected, this.thumbnailContainer);
    this.selectedThumbnailIndex = this.$thumbnails.index(this.$selectedThumbnail);

    this.flickity = new Flickity(this.thumbnailContainer, {
      cellAlign: 'left',
      imagesLoaded: true,
      prevNextButtons: false,
      pageDots: false,
      initialIndex: this.selectedThumbnailIndex,
    });
    this.flickity.reloadCells();

    this.flickity.on('select', this.flickitySelect);
    this.flickity.on('staticClick', this.flickityStaticClick);

    this.thumbnailPrev.addEventListener('click', this.flickityPrev);
    this.thumbnailNext.addEventListener('click', this.flickityNext);

    this.$thumbnailsWrapper.addClass(classes.product.slideshowThumbnailsFlickityEnabled);
  }

  _flickitySelect() {
    const $selectedElement = $(this.flickity.selectedElement);
    const imageId = $selectedElement.data('product-slideshow-thumbnail');
    const $image = $(`[data-product-slideshow-image="${imageId}"]`, this.el);
    const $thumbnail = $(`[data-product-slideshow-thumbnail="${imageId}"]`, this.el);

    this.$images.removeClass(classes.product.slideshowImageSelected);
    this.$thumbnails.removeClass(classes.product.slideshowThumbnailSelected);

    $image.addClass(classes.product.slideshowImageSelected);
    $thumbnail.addClass(classes.product.slideshowThumbnailSelected);

    this.$selectedThumbnail = $thumbnail;
    this.selectedThumbnailIndex = this.$thumbnails.index(this.$selectedThumbnail);
  }

  _flickityStaticClick(event, pointer, cellElement, cellIndex) {
    if (!cellElement) {
      return;
    }

    this.flickity.select(cellIndex);
  }

  _destroyFlickity() {
    if (this.flickity) {
      this.flickity.off('select', this.flickitySelect);
      this.flickity.off('staticClick', this.flickityStaticClick);
      this.thumbnailPrev.removeEventListener('click', this.flickityPrev);
      this.thumbnailNext.removeEventListener('click', this.flickityNext);
      this.$thumbnailsWrapper.removeClass(classes.product.slideshowThumbnailsFlickityEnabled);
      this.flickity.destroy();
      this.flickity = null;
    }
  }

  _initStaticGallery() {
    if (this.staticGallery) {
      return;
    }

    this.$thumbnails.each((index, el) => el.addEventListener('click', this.onThumbnailClick));
    this.$thumbnails.each((index, el) => el.addEventListener('keypress', this.onThumbnailKeyPress));
    this.staticGallery = true;
  }

  _destroyStaticGallery() {
    if (this.staticGallery) {
      this.$thumbnails.each((index, el) => el.removeEventListener('click', this.onThumbnailClick));
      this.$thumbnails.each((index, el) => el.removeEventListener('keypress', this.onThumbnailKeyPress));
      this.staticGallery = false;
    }
  }

  _onThumbnailKeyPress(event) {
    if (event.key === ' ' || event.key === 'Enter') {
      // Prevent the default action to stop scrolling when space is pressed
      event.preventDefault();

      const imageId = $(event.currentTarget).data('product-slideshow-thumbnail');

      this.switchImage(imageId);
    }
  }

  _onThumbnailClick(event) {
    const imageId = $(event.currentTarget).data('product-slideshow-thumbnail');

    this.switchImage(imageId);
  }
}
