import $ from 'jquery';
import debounce from 'just-debounce';
import layout from '../Layout';
import ProductCards from './ProductCards';
import ProductSlideshow from './ProductSlideshow';
import ProductMosaic from './ProductMosaic';
import ImageZoom from './ImageZoom';

export default class ProductGallery {
  constructor(options) {
    const defaults = {
      el: null,
      layout: 'slideshow',
      selectedImage: null,
    };

    this.config = $.extend({}, defaults, options);

    this.onResize = debounce(this._onResize, 15).bind(this);
    this._onResize();

    layout.onBreakpointChange(this.onResize);

    const pswpJsonElement = JSON.parse(this.config.el.querySelector('[data-photoswipe-images]').innerHTML);

    if (this.config.el.classList.contains('product-gallery-zoom')) {
      this.imageZoom = new ImageZoom(pswpJsonElement.images);

      $('[data-image-zoom]', this.config.el).on('click', e => this.imageZoom.open(e.currentTarget));
    }
  }

  switchImage(id) {
    if (!id) {
      return;
    }

    if (this.gallery && typeof this.gallery.switchImage === 'function') {
      this.gallery.switchImage(id);
    }
  }

  unload() {
    layout.offBreakpointChange(this.onResize);

    if (this.gallery && typeof this.gallery.destroy === 'function') {
      this.gallery.destroy();
    }

    if (this.imageZoom) {
      this.imageZoom.unload();
    }
  }

  _onResize() {
    switch (layout.getBreakpoint()) {
      case 'XS':
      case 'S':
        this._initMobile();
        this.switchImage(this.config.selectedImage);
        break;
      default:
        this._initDesktop();
        this.switchImage(this.config.selectedImage);
        break;
    }
  }

  _initMobile() {
    this._destroyGallery();

    this.galleryEl = this.config.el.querySelector('[data-product-cards]');
    this.gallery = new ProductCards({
      el: this.galleryEl,
    });
  }

  _initDesktop() {
    this._destroyGallery();

    switch (this.config.layout) {
      case 'slideshow':
        this.galleryEl = this.config.el.querySelector('[data-product-slideshow]');
        this.gallery = new ProductSlideshow({
          el: this.galleryEl,
        });
        break;
      case 'mosaic':
        this.galleryEl = this.config.el.querySelector('[data-product-mosaic]');
        this.gallery = new ProductMosaic({
          el: this.galleryEl,
        });
        break;
      default:
        break;
    }
  }

  _destroyGallery() {
    if (this.gallery && typeof this.gallery.destroy === 'function') {
      this.gallery.destroy();
    }
  }
}
