import $ from 'jquery';
import $script from 'scriptjs';
import CurrencyConverter from 'shopify-currency-converter';
import { classes, selectors } from '../Constants';
import HeaderDrawers from '../components/header/HeaderDrawers';
import MenuDrawer from '../components/header/MenuDrawer';
import Navigation from '../components/header/Navigation';
import StickyHeader from '../components/header/StickyHeader';

export default class StaticHeader {
  constructor(section) {
    this.currency = section.data.currency;
    this.settings = section.data.settings;
    this.$el = $(section.el);
    this.$body = $(document.body);

    this.mainHeader = section.el.querySelector(selectors.header.main);
    this.$mainHeader = $(this.mainHeader);

    this.$scripts = $('[data-scripts]');

    if (this.currency.enable) {
      $script(this.$scripts.data('shopify-currencies'), () => {
        this._converter();
      });
    }

    this.stickyHeader = new StickyHeader({
      $mainHeader: this.$mainHeader,
      settings: this.settings,
    });

    this.navigation = new Navigation(this.$mainHeader);

    this.menuDrawer = new MenuDrawer({
      $mainHeader: this.$mainHeader,
      settings: this.settings,
    });

    this.headerDrawers = new HeaderDrawers({
      $mainHeader: this.$mainHeader,
      settings: this.settings,
      currency: this.currency,
    });

    this._bindEvents();
  }

  onSectionUnload() {
    this.headerDrawers.unload();
    this.menuDrawer.unload();
    this.navigation.unload();
    this.stickyHeader.unload();
    this._setBorder(false);

    this.$mainHeader.off('header-drawers:toggled');
    this.$mainHeader.off('set-border');

    if (this.currency.enable) {
      this.$mainHeader.off('change.converter');
    }
  }

  onSectionSelect() {
    this.stickyHeader.onSectionSelect();
  }

  onSectionBlockSelect(block) {
    const drawerType = block.el.getAttribute(selectors.header.drawerItemAttr);
    if (drawerType && drawerType === 'notification') {
      this.headerDrawers.notificationSelect();
    }
  }

  onSectionBlockDeselect(block) {
    const drawerType = block.el.getAttribute(selectors.header.drawerItemAttr);
    if (drawerType && drawerType === 'notification') {
      this.headerDrawers.notificationDeselect();
    }
  }

  _bindEvents() {
    this.$mainHeader.on('header-drawers:toggled', (event, data) => this._triggerBorder(data));
    this.$mainHeader.on('set-border', (event, data) => this._setBorder(data.state));
    $('.story-notification-inner a, .story-notification-inner button').on('click', event => event.stopPropagation());
  }

  _converter() {
    CurrencyConverter.init({
      switcherSelector: selectors.header.currencyConverter,
      priceSelector: '.money',
      shopCurrency: this.currency.shop_currency,
      defaultCurrency: this.currency.default_currency,
      displayFormat: this.currency.display_format,
      moneyFormat: this.currency.money_format,
      moneyFormatNoCurrency: this.currency.money_format_no_currency,
      moneyFormatCurrency: this.currency.money_format_currency,
    });

    this.$mainHeader.on('change.converter', selectors.header.currencyConverter, event => {
      const currencyValue = event.currentTarget.value;

      this.$mainHeader.find(selectors.header.currencyConverter).each((index, converter) => {
        if (converter.value !== currencyValue) {
          converter.value = currencyValue;
        }
      });

      CurrencyConverter.setCurrency(currencyValue);
    });
  }

  _setBorder(state) {
    this.$body.toggleClass(classes.header.bordered, state);
  }

  _triggerBorder(data) {
    const drawer = data.drawer || false;
    const headerBorder = data.headerBorder;

    if (!drawer) {
      return console.warn('Drawer toggled trigger is missing drawer name');
    }

    // Only update border if drawer toggle is Story
    if (drawer !== 'story') {
      return;
    }

    // If headerBorder, set border, otherwise determine if border is needed
    if (headerBorder) {
      this._setBorder(true);
      this.$body.toggleClass(classes.header.bordered, true);
    } else {
      // Kep bordered class only if is scrolled, which is only if the header is sticky
      this._setBorder(this.$body.hasClass(classes.header.scrolled));
    }
  }
}
