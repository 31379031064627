import $ from 'jquery';
import { classes, selectors, events } from '../../Constants';
import PriceManager from '../PriceManager';
import Quantity from '../Quantity';
import CartRequests from './CartRequests';
import rimg from 'rimg-shopify';

export default class CartItem {
  constructor(el, cart, options) {
    this.$window = $(window);
    this.el = el;
    this.$el = $(el);
    this.cart = cart;
    this.moneyFormat = options.moneyFormat;
    this.variant = el.getAttribute('data-cart-item');
    this.quantityEl = el.querySelector(selectors.quantity.el);
    this.$removeEls = $(selectors.cart.item.remove, el);
    this.$priceEls = $(selectors.cart.item.price, el);
    this.totalEls = el.querySelectorAll(selectors.cart.item.total);
    this.priceManager = null;
    this.cartRequests = null;
    this.slideUpComplete = false;

    if (!this.variant) {
      this.cart.removeCartItem(this);
      this._destroy();
    }

    this.onClickRemove = this._onClickRemove.bind(this);
    this.onQuantityChange = this._onQuantityChange.bind(this);
    this.onCartItemLoadingStart = this._onCartItemLoadingStart.bind(this);
    this.onCartItemLoadingFinish = this._onCartItemLoadingFinish.bind(this);
    this.onSlideUpComplete = this._onSlideUpComplete.bind(this);

    this._init();
    rimg.watch(this.$el[0]);
  }

  _init() {
    this.priceManager = new PriceManager(this.el, this.moneyFormat);
    this.cartRequests = new CartRequests();
    this.quantity = new Quantity(
      this.quantityEl,
      {
        callback: this.onQuantityChange,
      },
    );

    this.priceManager.updateAll();

    this._bindEvents();
  }

  _bindEvents() {
    this.$removeEls.on('click', this.onClickRemove);
    this.$window.on(events.cart.item.loadingStart, this.onCartItemLoadingStart);
    this.$window.on(events.cart.item.loadingFinish, this.onCartItemLoadingFinish);
  }

  _unbindEvents() {
    this.$removeEls.off('click', this.onClickRemove);
    this.$window.off(events.cart.item.loadingStart, this.onCartItemLoadingStart);
    this.$window.off(events.cart.item.loadingFinish, this.onCartItemLoadingFinish);
  }

  _onClickRemove() {
    const attributes = {
      id: this.variant,
      quantity: 0,
    };

    this.cartRequests.submitItemRequest(attributes, true);
  }

  _onQuantityChange(event, data) {
    const attributes = {
      id: this.variant,
      quantity: data.quantity,
    };

    this.cartRequests.submitItemRequest(attributes, true);
  }

  _onCartItemLoadingStart(event, data) {
    const { variant, quantity } = data.request;

    if (this.variant === variant) {
      // Reset visuals, in case we were already loading
      this.$el
        .removeClass(classes.cart.item.loading)
        .removeClass(classes.cart.item.totalLoading);

      // Fade entire cart item, or just totals depending on context
      if (quantity === 0) {
        this.$el.addClass(classes.cart.item.loading);
      } else {
        this.quantity.setQuantity(quantity, false);
        this.$el.addClass(classes.cart.item.totalLoading);
      }
    }
  }

  _onCartItemLoadingFinish(event, data) {
    const { variant, quantity, total } = data.response;

    if (this.variant === variant) {
      if (quantity === 0) {
        // If quantity is 0, begin process of removing cart item from DOM
        this.$el
          .removeClass(classes.cart.item.loading)
          .removeClass(classes.cart.item.totalLoading)
          .addClass(classes.cart.item.sliding);

        $(selectors.cart.item.slide, this.$el).slideUp(this.onSlideUpComplete);
      } else {
        this.quantity.setQuantity(quantity, false);

        // Otherwise, update all prices with the new value and remove loading animation
        for (let i = 0; i < this.totalEls.length; i++) {
          const totalEl = this.totalEls[i];

          this.priceManager.updatePrice(totalEl, total);
        }

        this.$el
          .removeClass(classes.cart.item.loading)
          .removeClass(classes.cart.item.totalLoading);
      }
    }
  }

  _onSlideUpComplete() {
    if (this.slideUpComplete) {
      return;
    }

    // Emit trigger for components to react to removal
    this.$window.trigger(events.cart.item.removingFinished, { cart: this.cart });

    this.slideUpComplete = true;
    // Remove the item from the cart instance
    this.cart.removeCartItem(this);
    // Remove the item from the DOM
    this._destroy();
  }

  _destroy() {
    this.unload();
    this.$el.remove();
  }

  unload() {
    this._unbindEvents();
    this.quantity.unload();
  }
}
