import $ from 'jquery';

import { classes, selectors } from '../../Constants';

export default class Navigation {
  constructor($el) {
    this.$el = $el;

    this.events = [
      this.$el.on('click.navigation', selectors.navmenu.trigger, (event) => {
        event.preventDefault();
        this._toggleMenu(event.currentTarget.parentNode);
      }),
      this.$el.on('focusin.navigation', (event) => {
        this._navigationFocus(event.target);
      }),
    ];
  }

  /**
   * Clean up class when this.$el is being removed
   */
  unload() {
    this.events.forEach($el => $el.off('.navigation'));
  }

  /**
   * Close all open menus excluding target menu
   * @param menu
   */
  _closeAll(menu = false) {
    const openMenus = `${selectors.navmenu.submenu}.visible, ${selectors.navmenu.submenu}.animating-in`;

    this.$el.find(openMenus).parent().each((i, el) => {
      if (menu && $.contains(el, menu)) {
        return;
      }

      // Close any submenus in this tree
      $(el)
        .find(selectors.navmenu.trigger)
        .each((i, trigger) => this._closeItem(trigger));

      // Close root-level menu item
      this._closeItem(el);
    });
  }

  /**
   * Toggle visibility of a menu
   *
   * @param {HTMLElement} parentNode
   * @private
   */
  _toggleMenu(parentNode) {
    const $parent = $(parentNode);
    const $menu = $parent.children(selectors.navmenu.submenu);

    if ($menu.revealer('isVisible')) {
      this._closeItem(parentNode);
    } else {
      this._openItem(parentNode);
    }
  }

  /**
   * Retrieve information of immediate menu tree
   *
   * @param {HTMLElement} parentNode
   * @returns {{parent: Element, link: Element, menu: Element}}
   * @private
   */
  _menuTreeInfo(parentNode) {
    return {
      parent: parentNode,
      link: parentNode.querySelector(selectors.navmenu.trigger),
      menu: parentNode.querySelector(selectors.navmenu.submenu),
    };
  }

  /**
   * Toggle appropriate classes and flags for a menu parent
   *
   * @param {HTMLElement} parentNode
   * @param {Boolean} active
   * @private
   */
  _toggleParent(parentNode, active = true) {
    const { parent, link } = this._menuTreeInfo(parentNode);
    const $parent = $(parent);
    const $link = $(link);

    $parent.toggleClass(classes.navmenu.parentActive, active);
    $link.ariaExpanded(active);
  }

  /**
   * Open a menu item
   *
   * @param {HTMLElement} parentNode
   * @private
   */
  _openItem(parentNode) {
    this._toggleParent(parentNode, true);

    this._closeAll(parentNode);
    const $menu = $(parentNode.querySelector(selectors.navmenu.submenu));
    $menu.revealer('show');
  }

  /**
   * Close a menu item
   *
   * @param {HTMLElement} parentNode
   * @private
   */
  _closeItem(parentNode) {
    this._toggleParent(parentNode, false);

    const $menu = $(parentNode).children(selectors.navmenu.submenu);
    $menu.revealer('hide');
  }

  /**
   * Close all open menus when focusing out of the navigation, or when focusing
   * a top level item
   *
   * @param {HTMLElement} target
   * @private
   */
  _navigationFocus(target) {
    const inNavigation = $(target).parents(selectors.header.navigation).length;
    const isDepthOne = $(target).closest(selectors.navmenu.depthOne).length;
    const inDrawer = $(target)
      .parents(`[${selectors.header.drawerItemAttr}="menu"]`)
      .length;

    if (inDrawer) {
      return;
    }

    if (!inNavigation || isDepthOne) {
      this._closeAll();
    }
  }
}
