import $ from 'jquery';

/**
 * Traps the focus in a particular container
 *
 * @param {object} options - Options to be used
 * @property {jQuery} options.$container - Container to trap focus within
 * @property {string} options.namespace - Namespace used for new focus event handler
 */
function trapFocus(options = { namespace: null, focusParent: false }) {
  const { $container, namespace, focusParent } = options;
  const eventName = namespace ? `focusin.${namespace}` : 'focusin';
  const $focusable = focusParent
    ? $container
    : $container.find('a:visible:first').eq(0);

  $container.attr('tabindex', '-1');
  $focusable.focus();

  $(document).on(eventName, (event) => {
    if ($container[0] !== event.target && !$container.has(event.target).length) {
      $focusable.focus();
    }
  });
}


/**
 * Removes the trap of focus in a particular container
 *
 * @param {object} options - Options to be used
 * @property {jQuery} options.$container - Container of trapped focus
 * @property {string} options.namespace - Namespace used for new focus event handler
 */

function removeTrapFocus(options = {}) {
  const { $container, namespace } = options;
  const eventName = namespace ? `focusin.${namespace}` : 'focusin';

  if ($container && $container.length) {
    $container.attr('tabindex', '0');
  }

  $(document).off(eventName);
}

export {
  trapFocus,
  removeTrapFocus,
};
