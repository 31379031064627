import $ from 'jquery';

const eventHandlers = [];

function getBreakpoint() {
  return window
    .getComputedStyle(document.documentElement, ':after')
    .getPropertyValue('content')
    .replace(/"/g, '');
}

let previousBreakpoint = getBreakpoint();

$(window).on('resize', (event) => {
  const currentBreakpoint = getBreakpoint();

  if (previousBreakpoint !== currentBreakpoint) {
    eventHandlers.forEach((eventHandler) => {
      eventHandler(event, {
        previous: previousBreakpoint,
        current: currentBreakpoint,
      });
    });
  }

  previousBreakpoint = currentBreakpoint;
});

function isBreakpoint(breakpoint) {
  return getBreakpoint() === breakpoint;
}

function onBreakpointChange(eventHandler) {
  if (eventHandlers.indexOf(eventHandler) === -1) {
    eventHandlers.push(eventHandler);
  }
}

function offBreakpointChange(eventHandler) {
  const index = eventHandlers.indexOf(eventHandler);

  if (index !== -1) {
    eventHandlers.splice(index, 1);
  }
}

function crossesThreshold() {
  const previous = previousBreakpoint;
  const current = getBreakpoint();

  const mobileSizes = ['XS', 'S'];
  const desktopSizes = ['M', 'L', 'XL'];

  const desktopToMobile = desktopSizes.indexOf(previous) >= 0 && mobileSizes.indexOf(current) >= 0;
  const mobileToDesktop = mobileSizes.indexOf(previous) >= 0 && desktopSizes.indexOf(current) >= 0;

  return desktopToMobile || mobileToDesktop;
}

export default {
  getBreakpoint,
  isBreakpoint,
  onBreakpointChange,
  offBreakpointChange,
  crossesThreshold,
};
