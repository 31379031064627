import $ from 'jquery';

let instance = null;
let target = document.body;
let dismissIcon = '<svg width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M18.707 4.707a1 1 0 0 0-1.414 0l-5.585 5.586-5.586-5.586a1 1 0 1 0-1.415 1.414l5.586 5.586-5.586 5.586a1 1 0 0 0 0 1.414 1 1 0 0 0 1.415 0l5.586-5.586 5.585 5.586a1 1 0 1 0 1.415-1.414l-5.586-5.586 5.586-5.586a1 1 0 0 0 0-1.414z"/></svg>';
let maxConcurrentBanners = 3;

const Type = {
  success: 'success',
  error: 'error',
  warning: 'warning',
};

const Priority = {
  high: 0,
  medium: 1,
  low: 2,
};

class Banner {
  constructor(message, type, options) {
    this.message = message;
    this.type = type;
    this.autoDismiss = (options.autoDismiss === undefined) || options.autoDismiss;

    this.creationTime = Date.now();

    this._dismissed = false;
    this._dismissing = false;
    this.dismissPromise = null;
    this.onDismiss = this._onDismiss.bind(this);

    this.onTransitionEnd = this._onTransitionEnd.bind(this);

    this.bannerMessage = document.createElement('span');
    this.bannerMessage.classList.add('banner-message');
    this.bannerMessage.innerHTML = this.message;

    this.bannerDismiss = document.createElement('button');
    this.bannerDismiss.classList.add('banner-dismiss');
    this.bannerDismiss.innerHTML = dismissIcon;

    this.el = document.createElement('div');
    this.el.classList.add('banner');
    this.el.classList.add(`banner-type-${this.type}`);
    this.el.classList.add('banner-animating');
    this.el.classList.add('banner-animating-in');

    this.el.appendChild(this.bannerMessage);
    this.el.appendChild(this.bannerDismiss);

    target.appendChild(this.el);

    const hasTransition = !!window.getComputedStyle(this.el).getPropertyValue('transition');

    this.el.classList.remove('banner-animating');
    this.el.classList.remove('banner-animating-in');

    if (hasTransition) {
      this.el.addEventListener('transitionend', this.onTransitionEnd);

      setTimeout(() => {
        this.el.classList.add('banner-animating');
        this.el.classList.add('banner-animating-in');
      }, 10);
    } else {
      this.el.classList.add('banner-visible');
    }

    this.bannerDismiss.addEventListener('click', this.onDismiss);
  }

  get dismissed() {
    return this._dismissed;
  }

  update() {
    const deferred = $.Deferred();

    if (this._dismissing || this._dismissed) {
      deferred.resolve();
    } else if (
      this.autoDismiss
      && (Date.now() - this.creationTime) / 1000 >= this.autoDismiss
    ) {
      this._dismiss()
        .then(() => deferred.resolve());
    } else {
      deferred.resolve();
    }

    return deferred.promise();
  }

  destroy() {
    this.bannerDismiss.removeEventListener('click', this.onDismiss);
    target.removeChild(this.el);
  }

  _dismiss(force = false) {
    const deferred = $.Deferred();

    if (this._dismissing || (!force && !this.autoDismiss)) {
      deferred.resolve();
    } else {
      this._dismissing = true;
      this.dismissPromise = deferred.resolve;
      this.el.classList.add('banner-animating');
      this.el.classList.add('banner-animating-out');

      const hasTransition = !!window.getComputedStyle(this.el).getPropertyValue('transition');

      this.el.classList.remove('banner-animating');
      this.el.classList.remove('banner-animating-out');

      if (hasTransition) {
        this.el.addEventListener('transitionend', this.onTransitionEnd);

        setTimeout(() => {
          this.el.classList.remove('banner-visible');
          this.el.classList.add('banner-animating');
          this.el.classList.add('banner-animating-out');
        }, 10);
      } else {
        this._dismissed = true;
        this.el.classList.remove('banner-visible');
        deferred.resolve();
      }
    }

    return deferred.promise();
  }

  _onDismiss() {
    this._dismiss(true);
  }

  _onTransitionEnd() {
    this.el.removeEventListener('transitionend', this.onTransitionEnd);
    this.el.classList.remove('banner-animating');

    if (this.el.classList.contains('banner-animating-in')) {
      this.el.classList.remove('banner-animating-in');
      this.el.classList.add('banner-visible');
    }

    if (this.el.classList.contains('banner-animating-out')) {
      this.el.classList.remove('banner-animating-out');
    }

    if (this.dismissPromise) {
      this._dismissed = true;
      this.dismissPromise();
    }
  }
}

class BannerManager {
  constructor(options = {}) {
    if (!instance) {
      instance = this;
    } else {
      return instance;
    }

    target = options.target || target;
    maxConcurrentBanners = options.maxConcurrentBanners || maxConcurrentBanners;
    dismissIcon = options.dismissIcon || dismissIcon;

    this.queue = [];
    this.banners = [];
    this.adjustBannerLocation = this._adjustBannerLocation.bind(this);

    this.interval = setInterval(() => {
      this._update();
    }, 100);

    $(window).on('sticky-header.banner-manager', this.adjustBannerLocation);

    return instance;
  }

  add(message, type, priority, options = {}) {
    this.queue.push(
      {
        message,
        type,
        priority,
        options,
      },
    );
    this.queue.sort((a, b) => a.priority - b.priority);
  }

  unload() {
    $(window).off('sticky-header.banner-manager');
    clearInterval(this.interval);
    this.banners.forEach(banner => banner.destroy());
  }

  _update() {
    this.banners.forEach((banner) => {
      banner
        .update()
        .then(() => {
          if (banner.dismissed) {
            this.banners.splice(this.banners.indexOf(banner), 1);
            banner.destroy();
          }
        });
    });

    if (
      this.queue.length
      && this.banners.length < maxConcurrentBanners
    ) {
      const { message, type, options } = this.queue.shift();

      this.banners.push(new Banner(message, type, options));
    }
  }

  _adjustBannerLocation(event, data) {
    const { state, stickyOffset } = data;

    if (state) {
      target.classList.add('banners-sticky');
    } else {
      target.classList.remove('banners-sticky');
    }

    target.style.top = `${stickyOffset || 0}px`;
  }
}

export {
  Type,
  Priority,
  BannerManager,
  BannerManager as default,
};
