import $ from 'jquery';
import Flickity from 'flickity';
import layout from '../Layout';

export default class MobileScrollers {
  constructor(section) {
    this.el = section.el;
    this.flickity = null;
    this.slider = null;
    this.flickityDefaults = {
      contain: true,
      freeScroll: true,
      cellAlign: 'left',
      prevNextButtons: false,
      pageDots: false,
    };

    this._onBreakpointChange = this._onBreakpointChange.bind(this);
    layout.onBreakpointChange(this._onBreakpointChange);
  }

  /**
   * Initialize MobileScrollers
   *
   * @param {String} selector - String which can be queried to find element containing slides
   * @param {Object} flickityOptions - Options to initalize Flickity with
   */
  init(selector, flickityOptions = {}) {
    this.slider = this.el.querySelector(selector);
    this.flickityOptions = $.extend({}, this.flickityDefaults, flickityOptions);
    this.initFlickity();
  }

  initFlickity() {
    if (layout.isBreakpoint('L') || layout.isBreakpoint('XL')) {
      return this.destroyFlickity();
    }

    if (this.flickity || !this.slider) {
      return;
    }

    this.flickity = new Flickity(this.slider, this.flickityOptions);
    this.flickity.reloadCells();
  }

  destroyFlickity() {
    if (!this.flickity) {
      return;
    }

    this.flickity.destroy();
    this.flickity = null;
  }

  onSectionUnload() {
    this.destroyFlickity();
  }

  _onBreakpointChange() {
    this.initFlickity();
  }

  getElementHeight(elements) {
    let maxHeight = 0;
    elements.forEach((element) => {
      element.style.height = '';

      const currentHeight = element.offsetHeight;
      if (currentHeight > maxHeight) {
        maxHeight = currentHeight;
      }
    });

    return `${maxHeight}px`;
  }

  setElementHeight(elements, maxHeight) {
    elements.forEach((element) => {
      element.style.height = maxHeight;
    });
  }

  applyElementHeight(targetElement) {
    const targetElements = this.el.querySelectorAll(targetElement);
    const targetMaxHeight = this.getElementHeight(targetElements);
    this.setElementHeight(targetElements, targetMaxHeight);
  }
}
