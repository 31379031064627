import $ from 'jquery';
import ShopifyVariants from 'shopify-variants';
import Popover from '../helpers/Popover';
import { selectors, classes, events } from '../Constants';
import debounce from 'just-debounce';
import layout from '../Layout';
import Quantity from './Quantity';
import QuantitySubmit from './QuantitySubmit';
import Cart from '../components/cart/Cart';

export default class ProductAtc {
  constructor(options = {}) {
    this.$window = $(window);

    const defaults = {
      el: null,
      container: null,
      moneyFormat: null,
      product: null,
      cartRedirect: null,
      variantAvailable: null,
      textStrings: {
        add_to_cart: '',
        quantity_text: '',
        quantity_disable: '',
      },
      onATCSubmit: () => {},
      onATCSuccess: () => {},
    };

    if (options.textStrings) {
      this.config = {
        ...defaults,
        ...options,
        textStrings: { ...defaults.textStrings, ...options.textStrings },
      };
    } else {
      this.config = { ...defaults, ...options };
    }

    if (
      !this.config.el
      || !this.config.product
      || !this.config.textStrings
    ) {
      return console.warn('Unable to initiate ProductAtc.js');
    }

    this.$el = $(this.config.el);
    this.cartEl = this.config.el.querySelector(selectors.cart.el);
    this.$cartEl = $(this.cartEl);
    this.form = this.config.el.querySelector(selectors.product.form.el);
    this.quantityEl = this.config.el.querySelector(selectors.quantity.input);
    this.trigger = this.config.el.querySelector(selectors.popover.triggers.atcSteps);
    this.triggers = this.config.el.querySelectorAll(selectors.popover.triggers.atcToggle);
    this.contentForm = this.config.el.querySelector(selectors.popover.contents.atcStepForm);
    this.contentCart = this.config.el.querySelector(selectors.popover.contents.atcStepCart);
    this.submit = this.config.el.querySelector(selectors.product.form.submit);
    this.$submit = $(this.submit);
    this.$submitText = this.$submit.find(selectors.product.form.submitText);

    this.optionGroups = this.form.querySelectorAll('.option-value-group');
    this.$optionGroups = $(this.optionGroups);

    this.triggerFormPopover = new Popover(
      this.trigger,
      this.contentForm,
    );
    this.triggerCartPopover = new Popover(
      this.trigger,
      this.contentCart,
    );
    this.formCartPopoverFixed = new Popover(
      this.contentForm,
      this.contentCart,
    );
    this.formCartPopoverAbsolute = new Popover(
      this.contentForm,
      this.contentCart,
      {
        fixed: false,
        boundaryElement: this.config.container,
      },
    );
    this.activePopover = this.formCartPopoverAbsolute;
    this.activePopover.enable();

    this.formAtc = this.config.el.querySelector(selectors.product.form.atc);
    this.isStatic = this.$el.hasClass(classes.product.atc.static);
    this.isDefaultVariant = this.$el.hasClass(classes.product.atc.defaultVariant);
    this.submitting = false;

    this.variantHelper = null;
    this.quantity = null;
    this.cart = null;

    this.onTriggerClick = this._onTriggerClick.bind(this);
    this.onBodyClick = this._onBodyClick.bind(this);
    this.onSubmit = this._onSubmit.bind(this);
    this.onCartReloadFinish = this._onCartReloadFinish.bind(this);
    this.onCartItemRemoveFinish = this._onCartItemRemoveFinish.bind(this);
    this.onVariantSwitch = this._onVariantSwitch.bind(this);
    this.updatePopper = this._updatePopper.bind(this);

    this._init();
  }

  unload() {
    if (this.variantHelper) {
      this.variantHelper.unload();
      this.variantHelper = null;
    }

    this.quantity.unload();
    this.quantity = null;

    this.cart.cartRequests.restoreHandlers();

    if (this.triggers.length > 0) {
      for (let i = 0; i < this.triggers.length; i++) {
        this.triggers[i].removeEventListener('click', this.onTriggerClick);
      }
    }

    if (!this.isStatic) {
      layout.offBreakpointChange(this._onBreakpointChange);
    }

    document.body.removeEventListener('click', this.onBodyClick);
    this.form.removeEventListener('submit', this.onSubmit);
    this.$window.off('resize', this.updatePopper);
    this.$window.off(events.cart.inlineVisible, this.updatePopper);
    this.$cartEl.off(events.cart.reloadFinish, this.onCartReloadFinish);
    this.$window.off(events.cart.item.removingFinished, this.onCartItemRemoveFinish);
    this.$window.off('product-variant-switch', this.onVariantSwitch);
  }

  _init() {
    const $productVariants = $(selectors.product.form.variants, this.form);
    const $productOptions = $(selectors.product.form.productOption, this.form);

    this.cart = new Cart(this.cartEl, {
      moneyFormat: this.config.moneyFormat,
    });

    this.variantHelper = $productOptions.length
      ? new ShopifyVariants(
        this.config.product,
        $productVariants,
        $productOptions,
      )
      : null;

    if (this.submit) {
      this.quantity = new Quantity(
        this.form.querySelector(selectors.product.form.quantity),
      );
    } else {
      this.quantity = new QuantitySubmit(
        this.form.querySelector(selectors.product.form.quantity),
        {
          submitCallback: this.onSubmit,
          text: this.config.textStrings.quantity_text,
          disableText: this.config.textStrings.quantity_disable,
        },
      );
    }

    this._atcState(this.config.variantAvailable);

    if (this.triggers.length > 0) {
      for (let i = 0; i < this.triggers.length; i++) {
        this.triggers[i].addEventListener('click', this.onTriggerClick);
      }
    }

    if (!this.isStatic) {
      this._onBreakpointChange = this._onBreakpointChange.bind(this);
      layout.onBreakpointChange(this._onBreakpointChange);

      this._enablePopoverByBreakpoint(layout.getBreakpoint());
    }

    document.body.addEventListener('click', this.onBodyClick);
    this.form.addEventListener('submit', this.onSubmit);
    this.$window.on('resize', this.updatePopper);
    this.$window.on(events.cart.inlineVisible, this.updatePopper);
    this.$cartEl.on(events.cart.reloadFinish, this.onCartReloadFinish);
    this.$window.on(events.cart.item.removingFinished, this.onCartItemRemoveFinish);
    this.$window.on('product-variant-switch', this.onVariantSwitch);
  }

  _onBreakpointChange(events, breakpoints = {}) {
    this._enablePopoverByBreakpoint(breakpoints.current);
  }

  _changePopover(popover, force = false) {
    const deferred = $.Deferred();

    if (this.activePopover === popover) {
      return deferred.resolve().promise();
    }

    this.activePopover.disable(force).then(() => {
      this.activePopover = popover;
      this.activePopover.enable();

      deferred.resolve();
    });

    return deferred.promise();
  }

  _enablePopoverByBreakpoint(breakpoint) {
    let popover = null;

    switch (breakpoint) {
      case 'XS':
      case 'S':
        popover = this.isDefaultVariant
          ? this.formCartPopoverFixed
          : this.triggerFormPopover;
        break;
      case 'M':
      case 'L':
      case 'XL':
      default:
        popover = this.formCartPopoverAbsolute;
        break;
    }

    this._changePopover(popover, true);
  }

  _onTriggerClick(event) {
    event.stopPropagation();

    if (this.activePopover === this.triggerCartPopover) {
      this._changePopover(this.triggerFormPopover);
    } else {
      this.activePopover.toggle();
    }
  }

  _onBodyClick() {
    this.activePopover.close();
  }

  _onSubmit(event) {
    if (this.config.cartRedirect) {
      return;
    }

    event.preventDefault();

    if (this.quantityEl.getAttribute('disabled')) {
      return;
    }

    if (this.config.onATCSubmit) {
      this.config.onATCSubmit();
    }

    const form = $(this.form).serializeArray();

    const data = form.reduce((data, item) => {
      data[item.name] = item.value;
      return data;
    }, {});

    this.formAtc.classList.add(classes.product.atc.loading);
    this.cart.cartRequests.submitItemRequest(data, true, true);
    this.submitting = true;
  }

  _updatePopper() {
    this.activePopover.update();
  }

  _onCartReloadFinish(event, data) {
    if (!this.submitting) {
      return;
    }

    const error = data.error;

    this.formAtc.classList.remove(classes.product.atc.loading);

    if (error) {
      return;
    }

    if (this.triggers.length > 0) {
      for (let i = 0; i < this.triggers.length; i++) {
        this.triggers[i].removeEventListener('click', this.onTriggerClick);
      }

      this.triggers = this.config.el.querySelectorAll(selectors.popover.triggers.atcToggle);

      for (let i = 0; i < this.triggers.length; i++) {
        this.triggers[i].addEventListener('click', this.onTriggerClick);
      }
    }

    if (this.config.onATCSuccess) {
      this.config.onATCSuccess();
    }

    if (this.activePopover === this.triggerFormPopover) {
      this._changePopover(this.triggerCartPopover).then(
        () => this.activePopover.open(),
      );
    } else {
      this.activePopover.open();
    }

    this.submitting = false;
  }

  _onCartItemRemoveFinish(event, data) {
    const cart = data.cart;

    if (this.cart !== cart) {
      return;
    }

    this.activePopover.update();
  }

  _onVariantSwitch(event, data) {
    const variant = data.variant;

    this._atcState(variant.available);
  }

  _atcState(available) {
    this.quantity.setDisable(!available);

    if (this.$submit.length) {
      this.$submit.prop('disabled', !available);
      this.$submitText.text(available
        ? this.config.textStrings.add_to_cart
        : this.config.textStrings.quantity_disable);
    }
  }
}
