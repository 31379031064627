import { selectors } from '../Constants';
import Quantity from './Quantity';

export default class QuantitySubmit extends Quantity {
  constructor(el, options = {}) {
    super(el, options);
    this.text = options.text;
    this.disableText = options.disableText;
    this.submit = this.el.querySelector(selectors.quantity.submitEl);
    this.disabled = false;

    this.$input.trigger('change');
  }

  setDisable(disable) {
    if (disable) {
      this._setText(this.disableText);
      this.submit.setAttribute('disabled', 'disabled');
    } else {
      this.submit.removeAttribute('disabled');

      if (this.text && this.text.indexOf('** amount **') !== -1) {
        const text = this.text.replace('** amount **', this.$input.val());

        this._setText(text);
      }
    }

    super.setDisable(disable);
  }

  unload() {
    super.unload();
  }

  _onInputChange(event) {
    super._onInputChange(event);

    if (this.text && this.text.indexOf('** amount **') !== -1) {
      const text = this.text.replace('** amount **', this.$input.val());

      this._setText(text);
    }
  }

  _setText(text) {
    this.submit.value = text;
  }
}
