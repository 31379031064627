import ProductLayout from '../components/ProductLayout';
import PriceManager from '../components/PriceManager';

export default class DynamicProduct {
  constructor(section) {
    const { el } = section;
    const { product } = section.data;
    const variantAvailable = section.data.variant_available;
    const moneyFormat = section.data.money_format;
    const textStrings = section.data.text_strings;
    const cartRedirect = section.data.cart_redirect;

    this.priceManager = new PriceManager(section.el);
    this.priceManager.updateAll();
    this.productLayout = null;

    if (!product) {
      return;
    }

    this.productLayout = new ProductLayout({
      el,
      moneyFormat,
      product,
      variantAvailable,
      cartRedirect,
      textStrings,
    });
  }

  onSectionUnload() {
    if (this.productLayout) {
      this.productLayout.unload();
    }
  }
}
