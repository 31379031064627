import { selectors } from '../Constants';
import MobileScrollers from '../components/MobileScrollers';

export default class Gallery extends MobileScrollers {
  constructor(container) {
    super(container);

    this.init(selectors.gallery.container, {
      cellSelector: selectors.gallery.slide,
      cellAlign: 'center',
      imagesLoaded: true,
    });
  }
}
