import { selectors } from '../Constants';
import DeckCase from '../components/DeckCase';
import PriceManager from '../components/PriceManager';
import ProductItem from '../components/ProductItem';

export default class FeaturedProducts {
  constructor(section) {
    this.section = section;
    this.cardsEl = this.section.el.querySelector('[data-cards]');
    this.productItems = [];

    if (!this.cardsEl) {
      return;
    }

    this.deckCase = new DeckCase(this.cardsEl);

    this.priceManager = new PriceManager(this.section.el);
    this.priceManager.updateAll();

    this.productItemEls = this.section.el.querySelectorAll(selectors.product.item.el);

    for (let i = 0; i < this.productItemEls.length; i += 1) {
      this.productItems.push(new ProductItem(this.productItemEls[i]));
    }
  }

  onSectionUnload() {
    if (this.deckCase) {
      this.deckCase.onSectionUnload();
    }

    for (let i = 0; i < this.productItems.length; i += 1) {
      this.productItems[i].unload();
    }
  }
}
