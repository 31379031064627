import $ from 'jquery';
import debounce from 'just-debounce';
import HeaderCart from './HeaderCart';
import HeaderStory from './HeaderStory';
import getHeaderHeight from './getHeaderHeight';
import getAnnouncementBarHeight from './getAnnouncementBarHeight';
import HeaderNotification from './HeaderNotification';
import layout from '../../Layout';
import { classes, selectors } from '../../Constants';
import { removeTrapFocus, trapFocus } from '../../helpers/a11y';
import ScrollLock from '../../helpers/ScrollLock';

export default class HeaderDrawers {
  constructor(options) {
    this.settings = options.settings;
    this.currency = options.currency;
    this.$mainHeader = options.$mainHeader;
    this.mainHeader = this.$mainHeader[0];
    this.$window = $(window);
    this.$document = $(document);
    this.$drawerToggle = this.$mainHeader.find(selectors.header.drawerToggle);
    this.$drawers = this.$mainHeader.find(`[${selectors.header.drawerItemAttr}]`);

    this.isMobile = this._isMobile.bind(this);
    this._menuAfterOpen = this._menuAfterOpen.bind(this);
    this._menuAfterClose = this._menuAfterClose.bind(this);
    this._searchAfterOpen = this._searchAfterOpen.bind(this);
    this._storyAfterOpen = this._storyAfterOpen.bind(this);
    this._drawerBreakPoints = this._drawerBreakPoints.bind(this);
    layout.onBreakpointChange(this._drawerBreakPoints);

    this.$toggles = {
      account: this._getDrawerObject(selectors.header.drawerToggleAttr, 'account'),
      cart: this._getDrawerObject(selectors.header.drawerToggleAttr, 'cart'),
      menu: this._getDrawerObject(selectors.header.drawerToggleAttr, 'menu'),
      notification: this._getDrawerObject(selectors.header.drawerToggleAttr, 'notification'),
      story: this._getDrawerObject(selectors.header.drawerToggleAttr, 'story'),
      search: this._getDrawerObject(selectors.header.drawerToggleAttr, 'search'),
    };

    this.$drawerItems = {
      account: this._getDrawerObject(selectors.header.drawerItemAttr, 'account'),
      cart: this._getDrawerObject(selectors.header.drawerItemAttr, 'cart'),
      menu: this._getDrawerObject(selectors.header.drawerItemAttr, 'menu'),
      notification: this._getDrawerObject(selectors.header.drawerItemAttr, 'notification'),
      story: this._getDrawerObject(selectors.header.drawerItemAttr, 'story'),
      search: this._getDrawerObject(selectors.header.drawerItemAttr, 'search'),
    };

    this.headerStory = new HeaderStory({
      $mainHeader: this.$mainHeader,
      $drawer: this.$drawerItems.story,
      $toggle: this.$toggles.story,
      storyHandle: this.settings.story_handle,
    });

    this.headerCart = new HeaderCart({
      $mainHeader: this.$mainHeader,
      $drawer: this.$drawerItems.cart,
      currency: this.currency,
      isMobile: this.isMobile,
    });

    this.headerNotification = new HeaderNotification({
      $mainHeader: this.$mainHeader,
      $drawer: this.$drawerItems.notification,
      showNotification: this.settings.show_notification,
    });

    this.drawerConfigs = this._configureDrawers();

    this.scrollLock = new ScrollLock();

    this._bindEvents();
    this._positionDrawers();

    if (this.settings.story_handle) {
      this.headerStory.init();
      this.headerNotification.openNotification();
    }
  }

  unload() {
    this.$drawerToggle.off('.header-drawers');
    this.$drawers.off('.header-drawers');
    this.$mainHeader.off('header-drawers:close');
    this.$mainHeader.off('header-drawers:open');
    this.$window.off('.relative-drawers');

    this.headerStory.unload();
    this.headerNotification.unload();
    this.headerCart.unload();
    this._closeAllDrawers();
  }

  /**
   * When notification block is selected
   */
  notificationSelect() {
    this.headerNotification.openNotification(false, true);
  }

  /**
   * When notification block is deselected
   */
  notificationDeselect() {
    const isDismissed = this.headerNotification.isNotificationDismissed();

    if (isDismissed) {
      this._closeDrawer({ drawerToClose: 'notification' });
    }
  }

  _configureDrawers() {
    const defaultDrawerConfig = {
      afterOpen: () => {},
      afterClose: () => {},
      belowMobileHeader: true,
      canTrapFocus: true,
      scrollLock: true,
      focusParent: false,
    };

    const menuConfig = $.extend({}, defaultDrawerConfig, {
      afterOpen: this._menuAfterOpen,
      afterClose: this._menuAfterClose,
    });

    const accountConfig = $.extend({}, defaultDrawerConfig, {
      belowMobileHeader: false,
      scrollLock: false,
    });

    const notificationConfig = $.extend({}, defaultDrawerConfig, {
      belowMobileHeader: false,
      canTrapFocus: false,
      scrollLock: false,
    });

    const storyConfig = $.extend({}, defaultDrawerConfig, {
      afterOpen: this._storyAfterOpen,
      focusParent: true,
    });

    const searchConfig = $.extend({}, defaultDrawerConfig, {
      afterOpen: this._searchAfterOpen,
    });

    const cartConfig = $.extend({}, defaultDrawerConfig, {
      afterOpen: this.headerCart.afterOpen,
      afterClose: this.headerCart.afterClose,
    });

    return {
      account: accountConfig,
      cart: cartConfig,
      menu: menuConfig,
      notification: notificationConfig,
      story: storyConfig,
      search: searchConfig,
    };
  }

  _bindEvents() {
    this.$window.on('resize.relative-drawers', debounce(() => this._positionDrawers(), 15));

    this.$drawerToggle.on('click.header-drawers keypress.header-drawers', (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();

      let shouldFocus = false;

      if (event.type === 'keypress') {
        const code = event.charCode || event.keyCode;

        if ((code === 32) || (code === 13)) {
          shouldFocus = true;
        }
      }

      const drawerType = $(event.currentTarget).attr(selectors.header.drawerToggleAttr);
      this._toggleDrawer(drawerType, shouldFocus);
    });

    this.$mainHeader.on('header-drawers:close', (event, data) => {
      if (!data.drawer) {
        return console.warn('No drawer specified to close');
      }

      this._closeDrawer({ drawerToClose: data.drawer });
    });

    this.$mainHeader.on('header-drawers:open', (event, data) => {
      if (!data.drawer) {
        return console.warn('No drawer specified to open');
      }

      const drawerToOpen =  data.drawer;
      const skipClose = data.skipClose || false;

      this._openDrawer(drawerToOpen, skipClose);
    });

    this.$drawers.on('revealer-hide.header-drawers', (event) => {
      const closingDrawer = event.currentTarget.getAttribute(selectors.header.drawerItemAttr);
      const $openDrawers = this.$drawers.filter('.visible, .animating');
      if (closingDrawer === 'notification') {
        return;
      }

      if (!$openDrawers.length) {
        this.headerNotification.openNotification(true);
      }
    });
  }

  _getDrawerSelector(dataAttr, handle) {
    return `[${dataAttr}="${handle}"]`;
  }

  _getDrawerObject(dataAttr, handle) {
    return this.$mainHeader.find(this._getDrawerSelector(dataAttr, handle));
  }

  _isMobile() {
    return layout.isBreakpoint('XS') || layout.isBreakpoint('S');
  }

  _positionDrawers() {
    this._positionDrawer('account', 'account');
    this._positionDrawer('notification', 'story');
  }

  /**
   * Position drawers relative to their toggle
   *
   * @param {string} drawerItem - Name of drawer to position
   * @param {string} drawerToggle - Name of drawer to tether to
   * @private
   */
  _positionDrawer(drawerItem, drawerToggle) {
    const $drawer = this.$drawerItems[drawerItem];
    const $toggle = this.$toggles[drawerToggle];
    const rightGutter = 16;
    const clientWidth = document.body.clientWidth;

    if (!$drawer.length || !$toggle.length) {
      return;
    }

    const toggleRect = $toggle[0].getBoundingClientRect();
    const toggleMiddle = (toggleRect.left + (toggleRect.width / 2));
    const drawerWidth = parseInt(window.getComputedStyle($drawer[0]).width, 10);
    const drawerLeft = toggleMiddle - (drawerWidth / 2);
    const exceedsWindow = (drawerLeft + rightGutter + drawerWidth) >  clientWidth;

    // Update position
    let left = drawerLeft;
    let transformOrigin = '50% 0%'; // Desktop transform origin, if fits

    if (exceedsWindow) {
      left = clientWidth - drawerWidth - rightGutter;
    }

    if (!this._isMobile() && exceedsWindow) {
      // Use calculated left edge of drawer
      const offsetX = ((toggleMiddle - drawerLeft) / drawerWidth) * 100;
      transformOrigin = `${offsetX}% 0%`;
    }

    if (this._isMobile()) {
      // Unset right offset on mobile
      left = '';
      transformOrigin = '50% 100%';
    }

    $drawer.css({left, transformOrigin});
  }

  /**
   * Debounce method to close drawers when transitioning over to different displays
   *
   * @private
   */
  _drawerBreakPoints() {
    if (layout.crossesThreshold()) {
      this._closeAllDrawers('notification');
    }
  }

  _toggleButton(drawerType, state) {
    const $toggle = this.$toggles[drawerType];

    if (!$toggle || !$toggle.length) {
      return;
    }

    $toggle
      .ariaExpanded(state)
      .parent(selectors.header.drawerToggleParent)
      .toggleClass(classes.drawerToggles.active, state);
  }

  /**
   * Toggle visibility of a drawer
   *
   * @param drawerType
   * @private
   */
  _toggleDrawer(drawerType, shouldFocus = false) {
    if (this.$mainHeader.hasClass(classes.drawerVisibility[drawerType])) {
      this._closeDrawer({
        drawerToClose: drawerType,
        focusOnToggle: shouldFocus,
      });
    } else {
      this._openDrawer(drawerType, false, shouldFocus);
    }
  }

  /**
   * Open a specific drawer
   *
   * @param {string} drawerToOpen - Name of drawer to open
   * @param {boolean} skipClose - Skip closing of open menus
   * @private
   */
  _openDrawer(drawerToOpen, skipClose = false, shouldFocus = false) {
    const toggleExists = this.$toggles[drawerToOpen].length;
    const toggleDisabled = this.$toggles[drawerToOpen]
      .parent()
      .hasClass(classes.drawerToggles.disabled);

    if (toggleExists && toggleDisabled) {
      return;
    }

    const headerHeight = getHeaderHeight(this.mainHeader);
    const config = this.drawerConfigs[drawerToOpen];
    this.$drawerItems[drawerToOpen].css('max-height', window.innerHeight - headerHeight);

    if (!skipClose) {
      this._closeAllDrawers(drawerToOpen);
    }

    if (config.scrollLock) {
      this.scrollLock.lock();
    }

    if (this._isMobile()) {
      this.$document.trigger('action-bar:toggle', { hide: true });
    }

    this._openHeader(drawerToOpen);

    this.$drawerItems[drawerToOpen]
      .revealer('show')
      .ariaHidden(false)
      .one('revealer-show', () => {
        config.afterOpen();

        this.$mainHeader.trigger('header-drawers:toggled', {
          drawer: drawerToOpen,
          headerBorder: true,
        });

        if (config.canTrapFocus && shouldFocus) {
          trapFocus({
            $container: this.$drawerItems[drawerToOpen],
            namespace: `header-drawers-${drawerToOpen}`,
            focusParent: config.focusParent,
          });
        }

        this._bindDrawerClose(drawerToOpen);
      });
  }

  /**
   * Close a specific drawer.
   *
   * @param options
   * @property {string} options.drawerToClose - Drawer to close
   * @property {boolean} options.focusOnToggle - Should the closing drawer's toggle be focused
   * @private
   */
  _closeDrawer(options, shouldFocus = true) {
    const drawerToClose = options.drawerToClose;
    const focusOnToggle = options.focusOnToggle || false;
    const config = this.drawerConfigs[drawerToClose];

    this._closeHeader(drawerToClose);

    this.$drawerItems[drawerToClose]
      .revealer('hide')
      .one('revealer-hide', () => {
        this.$drawerItems[drawerToClose]
          .css('max-height', '')
          .ariaHidden(true);
        config.afterClose();
        this.$mainHeader.trigger('header-drawers:toggled', {
          drawer: drawerToClose,
          headerBorder: false,
        });

        if (config.canTrapFocus && shouldFocus) {
          removeTrapFocus({
            $container: this.$drawerItems[drawerToClose],
            namespace: `header-drawers-${drawerToClose}`,
          });

          if (focusOnToggle) {
            this.$toggles[drawerToClose].focus();
          }
        }
      });

    if (config.scrollLock) {
      this.scrollLock.unlock();
    }

    this._unbindDrawerClose(drawerToClose);

    if (this._isMobile()) {
      this.$document.trigger('action-bar:toggle', { hide: false });
    }
  }

  /**
   * Close all drawers. Optionally specify a drawer to return focus to.
   *
   * @param {string} keepOpen - Drawer to keep open
   * @private
   */
  _closeAllDrawers(keepOpen = '') {
    const $openDrawers = this.$drawers.filter('.visible, .animating');

    $openDrawers.each((i, el) => {
      const drawerToClose = el.getAttribute(selectors.header.drawerItemAttr);

      if (keepOpen !== drawerToClose) {
        this._closeDrawer({
          drawerToClose,
        });
      }
    });
  }

  /**
   * Set up listeners to close drawers based on user interaction
   *
   * @param drawerType
   * @private
   */
  _bindDrawerClose(drawerType) {
    // Notification drawer has its own close method
    if (drawerType === 'notification') {
      return;
    }

    this.$document.on(`touchstart.header-drawers-${drawerType}, click.header-drawers-${drawerType}`, (event) => {
      this._documentFocus(event.target, drawerType);
    });

    // Close drawer on esc key
    this.$document.on(`keyup.header-drawers-${drawerType}`, (event) => {
      if (event.keyCode === 27) {
        this._closeDrawer({
          drawerToClose: drawerType,
          focusOnToggle: true,
        });
      }
    });
  }

  /**
   * Remove listeners when drawer closes
   *
   * @param drawerType
   * @private
   */
  _unbindDrawerClose(drawerType) {
    this.$document
      .off(`touchstart.header-drawers-${drawerType}, click.header-drawers-${drawerType}`)
      .off(`keyup.header-drawers-${drawerType}`);
  }

  /**
   * Detect if document interaction occurs outside of a drawer
   *
   * @param target
   * @param drawerType
   * @private
   */
  _documentFocus(target, drawerType) {
    const isToggleButton = $(target).parents(this._getDrawerSelector(selectors.header.drawerItemAttr, drawerType)).length;
    const clickInHeader = $(target).parents(selectors.header.main).length;

    // If click/tap is in header, and is mobile, ignore. Otherwise multiple closes occur
    if (isToggleButton || (clickInHeader && this._isMobile())) {
      return;
    }

    this._closeDrawer({
      drawerToClose: drawerType,
    });
  }

  _openHeader(drawer) {
    const deferred = $.Deferred();
    const windowHeight = window.innerHeight;
    const config = this.drawerConfigs[drawer];
    const $drawer = this.$drawerItems[drawer];
    const drawerHeight = $drawer.outerHeight();
    const headerRect = this.mainHeader.getBoundingClientRect();
    const fromBottom = drawerHeight;
    const fromTop = Math.max(0, headerRect.top - drawerHeight);

    // Offset using 'top' only if height of drawer + header is larger than window
    const offsetByTop = fromBottom >= windowHeight ;
    let offset = fromBottom;
    let offsetProp = 'bottom';

    if (offsetByTop) {
      offset = fromTop;
      offsetProp = 'top';
    }

    if (config.belowMobileHeader && this._isMobile()) {
      if (offsetByTop) {
        this.$mainHeader.css({
          top: headerRect.top,
          bottom: 'auto',
        });
      }

      // Save original top for easing back to 'closed' position
      this.$mainHeader
        .data('offset-type', offsetByTop ? 'top' : 'bottom')
        .data('original-top', headerRect.top)
        .revealer('show')
        .one('revealer-animating', () => {
          this.$mainHeader.css(offsetProp, offset);
          deferred.resolve();
        });
    } else {
      deferred.resolve();
    }

    deferred.done(() => {
      this._toggleButton(drawer, true);
      this.$mainHeader
        .addClass(classes.drawerVisibility.general)
        .addClass(classes.drawerVisibility[drawer]);
    });
  }

  _supportsSticky() {
    return layout.isBreakpoint('L') || layout.isBreakpoint('XL') || layout.isBreakpoint('M');
  }

  _closeHeader(drawer) {
    const deferred = $.Deferred();
    const headerHeight = getHeaderHeight(this.mainHeader);
    const config = this.drawerConfigs[drawer];

    if (config.belowMobileHeader && this._isMobile()) {
      // Default to bottom for when transitioning from desktop to mobile
      const offsetType = this.$mainHeader.data('offset-type') || 'bottom';
      let offset = 0;

      if (offsetType === 'top') {
        offset = parseInt(this.$mainHeader.data('original-top'), 10);

        if (isNaN(offset)) {
          offset = `calc(100vh - ${headerHeight}px`;
        }
      }

      this.$mainHeader
        .revealer('hide')
        .one('revealer-animating', () => {
          this.$mainHeader.css(offsetType, offset);
          deferred.resolve();
        })
        .one('revealer-hide', () => {
          this.$mainHeader.css({
            top: '',
            bottom: '',
          });
        });
    } else {
      // Reset offsets when transitioning from mobile to desktop
      this.$mainHeader.css({
        top: (this.settings.sticky_header && this._supportsSticky()) ? getAnnouncementBarHeight() : '',
        bottom: '',
      });
      deferred.resolve();
    }

    deferred.done(() => {
      this.$mainHeader.data('original-top', '');
      this._toggleButton(drawer, false);
      this.$mainHeader
        .removeClass(classes.drawerVisibility.general)
        .removeClass(classes.drawerVisibility[drawer]);
    });
  }

  _menuAfterOpen() {
    if (!this._isMobile()) {
      return;
    }

    const $drawer = this.$drawerItems.menu;
    $drawer.css('height', $drawer.outerHeight());
  }

  _menuAfterClose() {
    this.$drawerItems.menu.css('height', '');
  }

  _searchAfterOpen() {
    this.$drawerItems.search
      .find(selectors.drawers.search.field)
      .focus();
  }

  _storyAfterOpen() {
    this.headerNotification.dismissNotification();
    this.$drawerItems.story.trigger('theme:rimg:watch');
  }
}
