import layout from '../Layout';
import CardsPlayer from './CardsPlayer';

export default class DeckCase {
  constructor(cardsContainer) {
    this.cardsContainer = cardsContainer;
    this.cardsPlayer = null;

    this._onBreakpointChange = this._onBreakpointChange.bind(this);

    layout.onBreakpointChange(this._onBreakpointChange);

    if (layout.isBreakpoint('XS') || layout.isBreakpoint('S')) {
      this.cardsPlayer = new CardsPlayer(this.cardsContainer);
    }
  }

  _onBreakpointChange(events, breakpoints = {}) {
    const current = breakpoints.current;

    if ((current === 'S' || current === 'XS') && !this.cardsPlayer) {
      this.cardsPlayer = new CardsPlayer(this.cardsContainer);
    } else if ((current !== 'S' && current !== 'XS') && this.cardsPlayer) {
      this.cardsPlayer.destroy();
      this.cardsPlayer = null;
    }
  }

  onSectionUnload() {
    if (this.cardsPlayer) {
      this.cardsPlayer.destroy();
      this.cardsPlayer = null;
    }
  }
}
