import Hammer from 'hammerjs';
import debounce from 'just-debounce';
import { classes, selectors } from '../Constants';

export default class CardsPlayer {
  constructor(el) {
    this.el = el;
    this.velocityThreshold = 0.3; // px/ms threshold before snapping off screen when released
    this.minVelocity = 0.3; // px/ms
    this.maxVelocity = 2; // px/ms
    this.snapThreshold = 1.25; // Distance percentage between card and cards container

    this.hintShown = false;

    this.cards = this.el.querySelectorAll(selectors.cardsPlayer.card);
    this.deck = null;
    this.hand = null;
    this.mc = null;
    this.panningCard = null;
    this.translatingCards = [];
    this.intersectionObserver = new IntersectionObserver(this._onTranslateOffscreen.bind(this), { threshold: 0 });
    this.requestAnimationFrameId = null;

    this._onResize = this._onResize.bind(this);
    this._onScroll = this._onScroll.bind(this);
    this._onHintRotateOut = this._onHintRotateOut.bind(this);
    this._onHintRotateIn = this._onHintRotateIn.bind(this);
    this._onPanStart = this._onPanStart.bind(this);
    this._onPan = this._onPan.bind(this);
    this._onPanEnd = this._onPanEnd.bind(this);
    this._onTap = this._onTap.bind(this);
    this._translateElementsOffScreen = this._translateElementsOffScreen.bind(this);

    this.init();
  }

  init() {
    this._createElements();
    this._setCardsContainerHeight();

    window.addEventListener('resize', this._onResize);

    if (this.cards.length <= 1) {
      return;
    }

    window.addEventListener('scroll', this._onScroll);

    this.pan = new Hammer.Pan(
      'pan',
      { direction: Hammer.DIRECTION_HORIZONTAL, threshold: 0 },
    );

    this.mc = new Hammer.Manager(this.cardsContainer, {
      touchAction: 'pan-y',
      recognizers: [
        [Hammer.Tap],
      ],
    });

    this.mc.add(this.pan);

    this.mc.on('panstart', this._onPanStart);
    this.mc.on('panleft panright', this._onPan);
    this.mc.on('panend pancancel', this._onPanEnd);
    this.mc.on('tap', this._onTap);

    this._onScroll();
  }

  getTopCardIndex() {
    const card = this.deck.querySelector(selectors.cardsPlayer.card);

    if (card) {
      for (let i = 0; i < this.cards.length; i += 1) {
        if (this.cards[i] === card) return i;
      }
    }

    return false;
  }

  drawToCardIndex(index) {
    if (index > this.cards.length - 1) {
      return;
    }

    this.cards = this.el.querySelectorAll(selectors.cardsPlayer.card);

    const card = this.cards[index];
    const maxIterations = 1000;

    for (let i = this.translatingCards.length - 1; i >= 0; i -= 1) {
      const el = this.translatingCards[i].el;

      this.translatingCards.splice(i, 1);
      this.deck.appendChild(el);
    }

    for (let i = 0; i < this.cards.length; i += 1) {
      const el = this.cards[i];
      const elVisual = el.querySelector(selectors.cardsPlayer.cardVisual);

      el.classList.remove(classes.cardsPlayer.repositioning);
      el.style.transform = '';
      elVisual.classList.remove(classes.cardsPlayer.hintingStart);
      elVisual.classList.remove(classes.cardsPlayer.hintingEnd);
      elVisual.classList.remove(classes.cardsPlayer.visualFirst);
    }

    let topCard = this.deck.querySelector(selectors.cardsPlayer.card);
    let iterations = 0;

    while (iterations <= maxIterations && topCard !== card) {
      this.deck.appendChild(topCard);

      iterations += 1;
      topCard = this.deck.querySelector(selectors.cardsPlayer.card);
    }
  }

  destroy() {
    window.removeEventListener('resize', this._onResize);
    window.removeEventListener('scroll', this._onScroll);

    if (this.mc) {
      this.mc.off('panstart', this._onPanStart);
      this.mc.off('panleft panright', this._onPan);
      this.mc.off('panend pancancel', this._onPanEnd);
      this.mc.destroy();
    }

    if (this.requestAnimationFrameId) {
      window.cancelAnimationFrame(this.requestAnimationFrameId);
    }

    this.drawToCardIndex(0);
    this._destroyElements();
  }

  _createElements() {
    this.cardsContainer = document.createElement('div');
    this.cardsContentContainer = document.createElement('div');
    this.deck = document.createElement('div');
    this.hand = document.createElement('div');

    for (let i = 0; i < this.cards.length; i += 1) {
      const card = this.cards[i];

      if (i === 0) {
        const cardVisual = card.querySelector(selectors.cardsPlayer.cardVisual);
        const cardContent = card.querySelector(selectors.cardsPlayer.cardContent);

        if (cardContent) {
          const contentClone = cardContent.cloneNode(true);

          this.cardsContentContainer.appendChild(contentClone);
        }

        cardVisual.classList.add(classes.cardsPlayer.visualFirst);
      }

      this.deck.appendChild(card);
    }

    this.cardsContainer.classList.add(classes.cardsPlayer.player);
    this.cardsContentContainer.classList.add(classes.cardsPlayer.content);
    this.deck.classList.add(classes.cardsPlayer.deck);
    this.hand.classList.add(classes.cardsPlayer.hand);

    this.cardsContainer.appendChild(this.deck);
    this.cardsContainer.appendChild(this.hand);
    this.el.appendChild(this.cardsContainer);
    this.el.appendChild(this.cardsContentContainer);
  }

  _setCardsContainerHeight() {
    let maxHeight = 0;

    for (let i = 0; i < this.cards.length; i += 1) {
      const card = this.cards[i];
      const height = card.getBoundingClientRect().height;

      maxHeight = maxHeight < height ? height : maxHeight;
    }

    this.cardsContainer.style.paddingBottom = `${maxHeight}px`;
  }

  _onResize() {
    this._setCardsContainerHeight();
  }

  _onScroll() {
    const elemRect = this.cardsContainer.getBoundingClientRect();
    const elemMiddle = elemRect.top + (elemRect.height / 2);

    const isVisible = elemMiddle <= (window.innerHeight || document.documentElement.clientHeight);

    if (isVisible && !this.hintShown) {
      const card = this.cards[0];
      const cardVisual = card.querySelector(selectors.cardsPlayer.cardVisual);

      window.removeEventListener('scroll', this._onScroll);
      card.addEventListener('transitionend', this._onHintRotateOut);

      cardVisual.classList.add(classes.cardsPlayer.hintingStart);

      this.hintShown = true;
    }
  }

  _onHintRotateOut(event) {
    const card = event.currentTarget;
    const cardVisual = card.querySelector(selectors.cardsPlayer.cardVisual);

    card.removeEventListener('transitionend', this._onHintRotateOut);
    card.addEventListener('transitionend', this._onHintRotateIn);
    cardVisual.classList.remove(classes.cardsPlayer.hintingStart);
    cardVisual.classList.add(classes.cardsPlayer.hintingEnd);
  }

  _onHintRotateIn(event) {
    const card = event.currentTarget;
    const cardVisual = card.querySelector(selectors.cardsPlayer.cardVisual);

    card.removeEventListener('transitionend', this._onHintRotateIn);
    cardVisual.classList.remove(classes.cardsPlayer.hintingEnd);
  }

  _onPanStart(event) {
    const { deltaX, deltaY } = event;

    if (this.panningCard !== null || Math.abs(deltaX) < Math.abs(deltaY)) {
      return;
    }

    const card = this.deck.querySelector(selectors.cardsPlayer.card);
    const cardVisual = card.querySelector(selectors.cardsPlayer.cardVisual);

    if (!card) {
      return;
    }

    card.removeEventListener('transitionend', this._onHintRotateOut);
    card.removeEventListener('transitionend', this._onHintRotateIn);
    card.classList.remove(classes.cardsPlayer.hintingStart);
    card.classList.remove(classes.cardsPlayer.hintingEnd);
    cardVisual.classList.remove(classes.cardsPlayer.visualFirst);

    this.panningCard = card;
    this.hand.appendChild(this.panningCard);
    this.panningCard.classList.remove(classes.cardsPlayer.repositioning);

    const topCard = this.deck.querySelector(selectors.cardsPlayer.card);
    const topCardVisual = topCard.querySelector(selectors.cardsPlayer.cardVisual);

    topCardVisual.classList.add(classes.cardsPlayer.visualFirst);
  }

  _onPan(event) {
    const { center, deltaX, isFinal } = event;

    if (this.panningCard && isFinal) {
      this.pan = new Hammer.Pan(
        'pan',
        { direction: Hammer.DIRECTION_HORIZONTAL, threshold: 0 },
      );

      this.mc.emit('panend', event);
      this.mc.add(this.pan);
    }

    if (!this.panningCard || (center.x === 0 && center.y === 0)) {
      return;
    }

    debounce(window.requestAnimationFrame(() => {
      const translate = `translateX(${deltaX})`;

      if (
        !this.panningCard
        || translate === this.panningCard.style.transform
      ) {
        return;
      }

      const containerRect = this.cardsContainer.getBoundingClientRect();
      const containerWidth = containerRect.width;
      const containerCenter = containerRect.left + (containerWidth / 2);

      const panningCardRect = this.panningCard.getBoundingClientRect();
      const panningCardWidth = panningCardRect.width;
      const panningCardCenter = panningCardRect.left + (panningCardWidth / 2);

      let threshold = 0;

      if (panningCardCenter / containerCenter >= 1) {
        const thresholdValue = ((1 + this.snapThreshold) - 1);
        threshold = 1 - (((panningCardCenter / containerCenter) / thresholdValue) - 1);
      } else {
        const thresholdValue = (1 - (1 - this.snapThreshold));
        threshold = (panningCardCenter / containerCenter) / thresholdValue;
      }

      threshold = Math.max(0, threshold) || 1;

      const card = (
        threshold === 1
        ? this.deck.querySelector(selectors.cardsPlayer.card)
        : this.panningCard
      );
      const cardContent = card.querySelector(selectors.cardsPlayer.cardContent);

      if (cardContent) {
        const contentClone = cardContent.cloneNode(true);

        this.cardsContentContainer.innerHTML = '';
        this.cardsContentContainer.appendChild(contentClone);
        this.cardsContentContainer.style.opacity = threshold;
      }

      this.panningCard.style.transform = `translateX(${deltaX}px)`;
    }), 16);
  }

  _onPanEnd(event) {
    const { center } = event;
    let { deltaX, velocity } = event;

    if (!this.panningCard) {
      return;
    }

    const isCenter = center.x === 0 && center.y === 0;

    deltaX = isCenter ? 0 : deltaX;
    velocity = isCenter ? 0 : velocity;

    const containerRect = this.cardsContainer.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const containerCenter = containerRect.left + (containerWidth / 2);

    const panningCardRect = this.panningCard.getBoundingClientRect();
    const panningCardWidth = panningCardRect.width;
    const panningCardCenter = panningCardRect.left + (panningCardWidth / 2);

    const metSnapThreshold = (
      panningCardCenter / containerCenter <= 1 - this.snapThreshold
      || panningCardCenter / containerCenter >= 1 + this.snapThreshold
    );

    const metVelocityThreshold = Math.abs(velocity) >= this.velocityThreshold;

    if (metSnapThreshold || metVelocityThreshold) {
      const translateDirection = panningCardCenter - containerCenter < 0 ? -1 : 1;
      const translateVelocity = Math.max(Math.min(Math.abs(velocity), this.minVelocity), this.maxVelocity);

      const card = this.deck.querySelector(selectors.cardsPlayer.card);
      const cardContent = card.querySelector(selectors.cardsPlayer.cardContent);

      if (cardContent) {
        const contentClone = cardContent.cloneNode(true);

        this.cardsContentContainer.innerHTML = '';
        this.cardsContentContainer.appendChild(contentClone);
        this.cardsContentContainer.style.opacity = 1;
      }

      this.translatingCards.push({
        el: this.panningCard,
        offset: deltaX,
        direction: translateDirection,
        velocity: translateVelocity,
        prevTimestamp: Date.now(),
      });
      this.intersectionObserver.observe(this.panningCard);

      this._translateElementsOffScreen();
      this.panningCard = null;
    } else {
      window.requestAnimationFrame(() => {
        const topCard = this.deck.querySelector(selectors.cardsPlayer.card);
        const topCardVisual = topCard.querySelector(selectors.cardsPlayer.cardVisual);
        const panningCardVisual = this.panningCard.querySelector(selectors.cardsPlayer.cardVisual);

        topCardVisual.classList.remove(classes.cardsPlayer.visualFirst);
        this.deck.insertBefore(this.panningCard, this.deck.firstChild);
        this.panningCard.classList.add(classes.cardsPlayer.repositioning);
        this.panningCard.style.transform = `translateX(${deltaX}px)`;

        topCardVisual.classList.remove(classes.cardsPlayer.visualFirst);
        panningCardVisual.classList.add(classes.cardsPlayer.visualFirst);

        window.requestAnimationFrame(() => {
          if (this.panningCard) {
            const cardContent = this.panningCard.querySelector(selectors.cardsPlayer.cardContent);

            if (cardContent) {
              const contentClone = cardContent.cloneNode(true);

              this.cardsContentContainer.innerHTML = '';
              this.cardsContentContainer.appendChild(contentClone);
              this.cardsContentContainer.style.opacity = 1;
            }

            this.panningCard.style.transform = '';
            this.panningCard = null;
          }
        });
      });
    }
  }

  _onTap() {
    const element = this.deck.querySelector(selectors.cardsPlayer.card);
    const url = element.getAttribute('data-cards-player-card-url');

    if (url) {
      window.location.href = url;
    }
  }

  _translateElementsOffScreen() {
    const timestamp = Date.now();
    let maxVelocity = 0;

    for (let i = this.translatingCards.length - 1; i >= 0; i -= 1) {
      const translatingElement = this.translatingCards[i];
      const {
        el,
        offset,
        direction,
        velocity,
        prevTimestamp,
      } = translatingElement;
      maxVelocity = velocity > maxVelocity ? velocity : maxVelocity;
      const elapsedTimeMilli = prevTimestamp ? timestamp - prevTimestamp : 1;
      const distance = direction * maxVelocity * elapsedTimeMilli;
      const newOffset = offset + distance;

      el.style.transform = `translateX(${newOffset}px)`;
      translatingElement.offset = newOffset;
      translatingElement.prevTimestamp = timestamp;
    }

    if (this.translatingCards.length === 0) return;

    this.requestAnimationFrameId = debounce(
      window.requestAnimationFrame(this._translateElementsOffScreen),
      16,
    );
  }

  _onTranslateOffscreen(entries) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        this.translatingCards.forEach((translatingCard) => {
          if (translatingCard.el === entry.target) {
            translatingCard.remove = true;
          }
        });

        this.intersectionObserver.unobserve(entry.target);
      }
    });

    this.translatingCards = this.translatingCards.filter((translatingCard) => {
      if (translatingCard.remove) {
        const { el } = translatingCard;
        this.deck.appendChild(el);
        el.style.transform = '';
      }

      return !translatingCard.remove;
    });
  }

  _destroyElements() {
    for (let i = 0; i < this.cards.length; i += 1) {
      const card = this.cards[i];

      this.el.appendChild(card);
    }

    this.cardsContainer.remove();
    this.cardsContentContainer.remove();
  }
}
