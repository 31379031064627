import $ from 'jquery';
import layout from '../Layout';
import { classes, selectors } from '../Constants';
import Popover from '../components/Popover';
import ProductItem from '../components/ProductItem';

export default class StaticCollectionPages {
  constructor(section) {
    this.el = section.el;
    this.$el = $(this.el);
    this.$document = $(document);
    this.$mainHeader = $(selectors.header.main);

    this.productItemEls = this.el.querySelectorAll(selectors.product.item.el);
    this.productItems = [];

    this.filterPopover = null;
    this.sortByPopover = null;
    this.events = null;

    this.utilityBars = {
      $utils: this.$el.find(selectors.collectionUtils.utils),
      $narrow: this.$el.find(selectors.collectionUtils.narrow),
      $wide: this.$el.find(selectors.collectionUtils.wide),
    };

    this.$filterItem = this.$el.find(selectors.collectionPopover.filterItem);
    this.$filterItemAdvanced = this.$el.find(selectors.collectionPopover.filterItemAdvanced);
    this.$sortTriggerButton = this.$el.find(selectors.collectionPopover.sortByItem);

    this._onBreakpointChange = this._onBreakpointChange.bind(this);
    layout.onBreakpointChange(this._onBreakpointChange);

    this.onPopoverShow = this._onPopoverShow.bind(this);
    this.onPopoverHide = this._onPopoverHide.bind(this);
    this.onFiltersShow = this._onFiltersShow.bind(this);

    this._init();
  }

  onSectionUnload() {
    this.$document.off('action-bar:toggle');
    layout.offBreakpointChange(this._onBreakpointChange);

    if (this.events) {
      this.events.forEach($el => $el.off('.collection'));
    }

    if (this.filterPopover) {
      this.filterPopover.destroy();
    }

    if (this.sortByPopover) {
      this.sortByPopover.destroy();
    }

    for (let i = 0; i < this.productItems.length; i += 1) {
      this.productItems[i].unload();
    }
  }

  _init() {
    this._moveUtilityBar();
    this._bindEvents();
    this._initPopovers();
    this._setSortByQueryParameters();

    for (let i = 0; i < this.productItemEls.length; i += 1) {
      this.productItems.push(new ProductItem(this.productItemEls[i]));
    }
  }

  _bindEvents() {
    this.$document.on('action-bar:toggle', (event = null, data = {}) => {
      this._toggleUtilityBar(data.hide);
    });

    this.events = [
      this.$filterItem.on('click.collection', event => this._toggleTag(event.currentTarget)),
      this.$filterItemAdvanced.on('click.collection', event => this._advancedTags(event)),
      this.$sortTriggerButton.on('click.collection', event => this._changeSortingButton(event.currentTarget)),
    ];
  }

  _onBreakpointChange() {
    if (layout.crossesThreshold()) {
      this._initPopovers();
    }

    this._moveUtilityBar();
  }

  /**
   * Initiate Popovers
   *
   * @private
   */
  _initPopovers() {
    if (!this.utilityBars.$utils.length) {
      return;
    }

    let popperOptions = {};
    const isMobile = layout.isBreakpoint('XS') || layout.isBreakpoint('S');

    // Popovers assumes content is top oriented by default
    if (!isMobile) {
      popperOptions = {
        placement: 'bottom',
        modifiers: {
          computeStyle: {
            x: 'bottom',
          },
        },
      };
    }

    const filterTrigger = this.el.querySelector(selectors.popover.triggers.collectionFilter);
    const filterContent = this.el.querySelector(selectors.popover.contents.collectionFilter);

    const sortByTrigger = this.el.querySelector(selectors.popover.triggers.collectionSortBy);
    const sortByContent = this.el.querySelector(selectors.popover.contents.collectionSortBy);

    if (filterTrigger && filterContent) {
      if (this.filterPopover) {
        this.filterPopover.destroy();
      }

      const popoverOptions = {
        popperOptions,
        callbacks: {
          onShow: this.onFiltersShow,
          onHide: this.onPopoverHide,
        },
        content: filterContent,
        trigger: filterTrigger,
        perfectCenter: isMobile,
      };

      this.filterPopover = new Popover(popoverOptions);
    }

    if (sortByTrigger && sortByContent) {
      if (this.sortByPopover) {
        this.sortByPopover.destroy();
      }
      const popoverOptions = {
        popperOptions,
        callbacks: {
          onShow: this.onPopoverShow,
          onHide: this.onPopoverHide,
        },
        content: sortByContent,
        trigger: sortByTrigger,
        perfectCenter: isMobile,
      };

      this.sortByPopover = new Popover(popoverOptions);
    }
  }

  _onPopoverShow() {
    this.$mainHeader
      .addClass(classes.popover.hideHeader)
      .one('trend', () => {
        if (this.filterPopover) {
          this.filterPopover.update();
        }

        if (this.sortByPopover) {
          this.sortByPopover.update();
        }
      });
  }

  _onFiltersShow() {
    this._onPopoverShow();
  }

  _onPopoverHide() {
    this.$mainHeader.removeClass(classes.popover.hideHeader);
  }

  /**
   * Move utility bar in the DOM as needed for layout
   *
   * @private
   */
  _moveUtilityBar() {
    if (!this.utilityBars.$utils.length) {
      return;
    }

    if (layout.isBreakpoint('L') || layout.isBreakpoint('XL')) {
      if ($.contains(this.utilityBars.$narrow[0], this.utilityBars.$utils[0])) {
        return;
      }

      const $utils = this.utilityBars.$utils.detach();
      this.utilityBars.$narrow.append($utils);
      this._toggleUtilityBar(false);
    } else {
      if ($.contains(this.utilityBars.$wide[0], this.utilityBars.$utils[0])) {
        return;
      }

      const $utils = this.utilityBars.$utils.detach();
      this.utilityBars.$wide.append($utils);
      this._toggleUtilityBar(false);
    }
  }

  /**
   * Show or hide utility bar
   *
   * @param state
   * @private
   */
  _toggleUtilityBar(state) {
    this.utilityBars.$utils.toggleClass('hidden', state);

    if (this.filterPopover) {
      this.filterPopover.hidePopover();
    }

    if (this.sortByPopover) {
      this.sortByPopover.hidePopover();
    }
  }

  /**
   * Make Shopify aware of relevent collection search info
   *  - tag
   *  - vendor
   *  - pagination
   *  - sorting criteria
   *
   * @private
   */
  _setSortByQueryParameters() {
    Shopify.queryParams = {};
    if (location.search.length) {
      for (let i = 0, aCouples = location.search.substr(1).split('&'); i < aCouples.length; i++) {
        const aKeyValue = aCouples[i].split('=');
        // Reset the page number when we apply (i.e. don't add it to params)
        if (aKeyValue.length > 1 && aKeyValue[0] !== 'page') {
          Shopify.queryParams[decodeURIComponent(aKeyValue[0])] = decodeURIComponent(aKeyValue[1]);
        }
      }
    }
  }

  /**
   * Update buttons styles when changing collection sorting
   *
   * @param target
   * @private
   */
  _changeSortingButton(target) {
    $(target)
      .parent()
      .addClass(classes.popover.listItemActive)
      .siblings(`.${classes.popover.listItemActive}`)
      .removeClass(classes.popover.listItemActive);

    this._changeSorting(target);
  }

  /**
   * Change sorting of collection
   *
   * @param target
   * @private
   */
  _changeSorting(target) {
    const $target = $(target);

    Shopify.queryParams.sort_by = $target.val();
    location.search = jQuery.param(Shopify.queryParams).replace(/\+/g, '%20');
  }

  /**
   * Toggle tag active state before page transition
   *
   * @param target
   * @private
   */
  _toggleTag(target) {
    $(target)
      .parent()
      .addClass(classes.popover.listItemActive)
      .siblings(`.${classes.popover.listItemActive}`)
      .removeClass(classes.popover.listItemActive);
  }

  _advancedTags(event) {
    const target = event.currentTarget;
    const $parent = $(target).parent();

    const tagGroup = $parent.attr('data-group');
    const tagHandle = $parent.attr('data-handle');
    const $activeGroup = $(`.${classes.popover.listItemActive}[data-group=${tagGroup}]`);

    // If item is active, perform as normal
    // If there are there no active items in group, perform as normal
    if ($parent.hasClass(classes.popover.listItemActive) || !$activeGroup.length) {
      return this._toggleTag(target);
    }

    // Item is not active, and there are no items active in group
    event.preventDefault();
    this._toggleTag(target);

    location.href = location.href
      // swap tag
      .replace($activeGroup.attr('data-handle'), tagHandle)
      // go back to page 1
      .replace(/(&page=\d+)|(page=\d+&)|(\?page=\d+$)/, '');
  }
}
