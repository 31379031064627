import $ from 'jquery';
import Flickity from 'flickity';
import 'flickity-imagesloaded';
import { selectors } from '../Constants';
import layout from '../Layout';

export default class DynamicSlideshow {
  constructor(section) {
    this.$el = $(section.el);
    this.$body = $(document.body);
    this.data = section.data;

    this.$slideshow = this.$el.find(selectors.slideshow.container);
    this.$slides = this.$el.find(selectors.slideshow.slide);
    this.$paginationNext = this.$el.find(selectors.slideshow.paginationNext);
    this.$paginationPrev = this.$el.find(selectors.slideshow.paginationPrev);

    this._setCellHeights = this._setCellHeights.bind(this);
    this._onSlideSettle = this._onSlideSettle.bind(this);
    this.selectSlide = this.selectSlide.bind(this);

    this.flickity = null;
    this.events = null;
    this.autoplay = this.data.autoplay
      ? this.data.autoplay * 1000
      : this.data.autoplay;

    // Only start Slideshow if container exists
    if (!this.$slideshow.length) {
      return;
    }

    window.addEventListener('resize', this._setCellHeights);

    this._initFlickity();
  }

  onSectionBlockSelect(block) {
    if (!this.flickity) {
      return;
    }

    const slideIndex = this._getSlideIndex(block.el);
    this.selectSlide(slideIndex, true, false);

    if (this.autoplay) {
      this.flickity.stopPlayer();
    }
  }

  onSectionBlockDeselect() {
    if (!this.flickity) {
      return;
    }

    if (this.autoplay) {
      this.flickity.playPlayer();
    }
  }

  onSectionUnload() {
    if (!this.flickity) {
      return;
    }

    if (this.events) {
      this.events.forEach($el => $el.off('.slideshow'));
    }

    this.flickity.off('settle', this._onSlideSettle);
    this.flickity.destroy();

    window.removeEventListener('resize', this._setCellHeights);
  }

  /**
   *  Move to the active slide
   * @param slideIndex
   * @param isWrap
   * @param isInstant
   */
  selectSlide(slideIndex, isWrap = false, isInstant = false) {
    if (slideIndex === this.flickity.selectedIndex) {
      return;
    }

    this.flickity.select(slideIndex, isWrap, isInstant);
    this.flickity.reloadCells();

    const cell = this.$slides[slideIndex];
    const $focusable = $(cell)
      .find(`${selectors.slideshow.pagination}, ${selectors.slideshow.linkButton}`)
      .eq(0);
    $focusable.focus();
  }

  /**
   * Get the index of the slide from the element
   *
   * @param el
   * @returns {Number}
   * @private
   */
  _getSlideIndex(el) {
    return parseInt(el.getAttribute('data-slide'), 10);
  }

  /**
   * Tabbing through can cause weird positioning, this makes sure active slide
   * is always properly positioned
   * @private
   */
  _onSlideSettle() {
    const cell = this.$slides[this.flickity.selectedIndex];

    this._minCellHeight(cell);
    this.flickity.reposition();
  }

  /**
   *
   * @private
   */
  _setCellHeights() {
    this.flickity.cells.forEach((cell) => {
      this._minCellHeight(cell.element);
    });
  }

  /**
   * Set the minimum height of the cell (via the image) to be height of the
   * content
   *
   * @param {Element} el
   * @private
   */
  _minCellHeight(el) {
    // Only perform on full-width slides
    if (el.getAttribute('data-slide-layout') !== 'full-width') {
      return;
    }

    const content = el.querySelector(selectors.slideshow.slideContent);
    const image = el.querySelector(selectors.slideshow.slideImage);

    if (!content || !image) {
      return;
    }

    const contentHeight = content.getBoundingClientRect().height;
    const imageHeight = image.getBoundingClientRect().height;

    const $image = $(image);

    if (layout.isBreakpoint('XS') || layout.isBreakpoint('S')) {
      $image.css('min-height', '');

      this.flickity.reposition();
    } else {
      if (contentHeight === imageHeight) {
        return;
      }

      if (contentHeight > imageHeight) {
        $image.css('min-height', contentHeight);
      } else {
        $image.css('min-height', '');
      }

      this.flickity.reposition();
    }
  }

  _initFlickity() {
    this.flickity = new Flickity(this.$slideshow[0], {
      accessibility: true,
      adaptiveHeight: true,
      autoPlay: this.autoplay,
      cellAlign: 'left',
      cellSelector: selectors.slideshow.slide,
      friction: 0.8,
      pageDots: false,
      pauseAutoPlayOnHover: true,
      prevNextButtons: false,
      selectedAttraction: 0.2,
      wrapAround: true,
    });

    this._setCellHeights();
    this._bindEvents();
  }

  _bindEvents() {
    this.events = [
      this.$slides.on('focusin.slideshow', (event) => {
        event.preventDefault();
        event.stopPropagation();

        const slideIndex = this._getSlideIndex(event.currentTarget);

        // Instant transitions confuse Flickity going back
        // Non-instant transitions confuse Flickity going forward
        const isInstant = slideIndex > this.flickity.selectedIndex;

        this.selectSlide(slideIndex, false, isInstant);
      }),
      this.$paginationNext.on('click.slideshow', () => this.flickity.next()),
      this.$paginationPrev.on('click.slideshow', () => this.flickity.previous()),
    ];

    this.flickity.on('settle', this._onSlideSettle);
  }
}
