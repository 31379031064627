import $ from 'jquery';
import $script from 'scriptjs';

export default class StaticGiftCard {
  constructor(section) {
    const $scripts = $('[data-scripts]');
    const qrCodeEl = section.el.querySelector('[data-qrcode]');

    if (!qrCodeEl) return;

    this.selectText = this._selectText.bind(this);
    this.qrCodeText = section.el.querySelector('[data-select-code]');

    $script($scripts.data('shopify-qrcode'), () => {
      const qrCode = qrCodeEl.getAttribute('data-qrcode');

      this.qrCode = new QRCode(qrCodeEl, {
        text: qrCode,
        width: 120,
        height: 120,
      });
    });

    this.qrCodeText.addEventListener('click', this.selectText);
  }

  onSectionUnload() {
    this.qrCodeText.removeEventListener('click', this.selectText);
    this.qrCode = null;
  }

  _selectText() {
    let range = '';

    if (document.body.createTextRange) { // ms method
      range = document.body.createTextRange();
      range.moveToElementText(this.qrCodeText);
      range.select();
    } else if (window.getSelection) { // moz, opera, webkit method
      const selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(this.qrCodeText);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}
