import $ from 'jquery';
import { selectors } from '../Constants';

export default class Newsletter {
  constructor() {
    this.$body = $(document.body);
    this.$formPosted = this.$body.find(selectors.newsletter.formPosted);

    if (this.$formPosted.length) {
      const scrollTop = this.$formPosted
        .eq(0)
        .parents('.shopify-section')
        .offset()
        .top;

      $('html, body').animate({
        scrollTop,
      }, 500, 'linear');
    }
  }
}
