import PhotoSwipe from 'photoswipe';
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';

const photoswipeEl = document.querySelector('[data-photoswipe]');

export default class ImageZoom {
  constructor(images) {
    this.images = images;
  }

  open(image = null) {
    const imageId = parseInt(image.getAttribute('data-image-zoom'), 10);
    let index = 0;

    if (imageId != null) {
      for (let i = 0; i < this.images.length; i++) {
        if (this.images[i].id === imageId) {
          index = i;
          break;
        }
      }
    }

    const options = {
      arrowEl: false,
      barsSize: { top: 0, bottom: 'auto' },
      captionEl: false,
      clickToCloseNonZoomable: false,
      closeOnScroll: false,
      counterEl: false,
      fullscreenEl: false,
      getThumbBoundsFn: () => {
        // get window scroll Y
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;

        // get position of element relative to viewport
        const rect = image.getBoundingClientRect();

        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
      },
      index,
      shareEl: false,
      showHideOpacity: true,
      timeToIdle: false,
      timeToIdleOutside: false,
      zoomEl: false,
    };

    // Initializes and opens PhotoSwipe
    this.photoswipe = new PhotoSwipe(
      photoswipeEl,
      PhotoSwipeUIDefault,
      this.images,
      options,
    );
    this.photoswipe.init();
  }

  close() {
    if (this.photoswipe) {
      this.photoswipe.close();
    }
  }

  unload() {
    this.close();
  }
}
