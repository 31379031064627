import $ from 'jquery';
import RichText from '../components/RichText';

export default class StaticFaq {
  constructor(section) {
    this.faq = section.el ? new RichText(section.el, 'accordion') : false;
  }

  onSectionUnload() {
    if (this.faq) {
      this.faq.unload();
    }
  }

  onSectionBlockSelect(block) {
    const $trigger = $(block.el);

    if (!$trigger.hasClass('active')) {
      $trigger.trigger('click');
    }
  }
}
