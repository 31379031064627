import $ from 'jquery';
import { selectors, classes } from '../Constants';

export default class Quantity {
  constructor(el, options = {}) {
    this.el = el;
    this.callback = options.callback;
    this.decrementButton = this.el.querySelector(selectors.quantity.decrement);
    this.$formElement = $(selectors.quantity.element, this.el);
    this.$input = $(selectors.quantity.input, this.el);
    this.min = this.$input.attr('min') ? parseInt(this.$input.attr('min'), 10) : 0;
    this.max = this.$input.attr('max') ? parseInt(this.$input.attr('max'), 10) : Infinity;
    this.incrementButton = this.el.querySelector(selectors.quantity.increment);

    this.decrementInput = () => this._adjustInput(-1);
    this.incrementInput = () => this._adjustInput(+1);

    this.onInputChange = (event) => this._onInputChange(event);

    this.decrementButton.addEventListener('click', this.decrementInput);
    this.$input.on('change.quantity', this.onInputChange);
    this.incrementButton.addEventListener('click', this.incrementInput);
  }

  setDisable(disable) {
    this.$input.prop('disabled', disable);
  }

  setQuantity(quantity, triggerEvent = true) {
    this._setValue(quantity);

    if (triggerEvent) {
      this.$input.trigger('change');
    }
  }

  unload() {
    this.decrementButton.removeEventListener('click', this.decrementInput);
    this.$input.off('change.quantity', this.onInputChange);
    this.incrementButton.removeEventListener('click', this.incrementInput);
  }

  _onInputChange(event) {
    const quantity = this._setValue(this.$input.val());

    if (this.callback) {
      this.callback(event, { quantity });
    }
  }

  /**
   * Make sure that value is a number
   *
   * @param {number|string} value
   * @returns {number}
   * @private
   */
  _formatValue(value) {
    const baseValue = parseInt(value, 10);
    return isNaN(baseValue) ? this.min : baseValue;
  }

  /**
   * Increment or decrement input based on adjustment
   *
   * @param {number} adjustment
   * @private
   */
  _adjustInput(adjustment) {
    if (this.$input.prop('disabled')) {
      return;
    }

    const value = this._formatValue(this.$input.val()) + adjustment;

    this.$input.val(value);
    this.$input.trigger('change');
  }

  /**
   * Restrict input to be within a valid number range
   *
   * @param {number} value
   * @returns {number}
   * @private
   */
  _restrictValue(value) {
    let val = Math.min(this.max, value); // Make sure value isn't larger than maximum
    val = Math.max(this.min, val); // Make sure value isn't lower than minimum

    return val;
  }

  /**
   * Set the value of the input to a sanitized state
   *
   * @param {number|string} value
   * @returns {number}
   * @private
   */
  _setValue(value) {
    const formatted = this._formatValue(value);
    const constrainedValue = this._restrictValue(formatted);

    // If value is empty, or initial value doesn't match constrained value
    if (!value || value !== constrainedValue) {
      this.$input.val(constrainedValue);
    }

    return constrainedValue;
  }
}
