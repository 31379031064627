import CurrencyConverter from 'shopify-currency-converter';

export default class PriceManager {
  constructor(el, moneyFormat) {
    this.el = el;
    this.moneyFormat = moneyFormat || null;
    this.prices = el.querySelectorAll('.money');
  }

  /**
   * Update all prices within element
   *
   * TODO: If this needs to be used more abstractly, then change:
   * updateAll() to updateAll(els = this.prices), and update internal references
   */
  updateAll() {
    for (let i = 0; i < this.prices.length; i++) {
      this.updateCurrency(this.prices[i]);
    }
  }

  /**
   * Update a price to match store formatting
   *
   * @param el
   * @param price
   * @private
   */
  updatePrice(el, price) {
    el.innerHTML = Shopify.formatMoney(price, this.moneyFormat);
    this.updateCurrency(el);
  }

  /**
   * Update an element to match selected currency.
   *
   * @param el
   */
  updateCurrency(el) {
    CurrencyConverter.update(el);
  }
}
