import $ from 'jquery';
import rimg from 'rimg-shopify';
import { classes, selectors } from '../Constants';
import Gallery from './Gallery';

export default class DynamicInstagramFeed extends Gallery {
  constructor(section) {
    super(section);

    this.settings = section.data;
    this.accessToken = this.settings.access_token;
    this.gallery = section.el.querySelector(selectors.instagram.container);
    this.$gallery = $(this.gallery);
    this.$galleryImages = this.$gallery.find(selectors.instagram.galleryImage);
    this.$placeholders = this.$gallery.find(selectors.instagram.placeholder);

    this.photos = [];
    this.processPhotos = this._processPhotos.bind(this);

    if (!this.accessToken || !this.accessToken.length) {
      return;
    }

    this.$gallery.on('rimg:enter', () => {
      this._getPhotos()
        .done(this.processPhotos)
        .fail((error) => {
          console.warn(`Instagram: ${ error }`);
        });
    });

    rimg.instance.track(this.gallery);
  }

  onSectionUnload() {
    rimg.instance.untrack(this.gallery);
    this.$gallery.off('rimg:enter');

    super.onSectionUnload();
  }

  _getPhotos(callback = null) {
    const url = `https://api.instagram.com/v1/users/self/media/recent?access_token=${ this.accessToken }&count=6&callback=`;
    const photoPromise = $.ajax(url, {
      dataType: 'jsonp',
    });

    return photoPromise.then((response) => {
      if (response.meta.code !== 200) {
        const error = `${ response.meta.error_message } (${ response.meta.error_type })`;
        return $.Deferred().reject(error).promise();
      }

      this.photos = response.data;

      if (callback) {
        return callback();
      }
    });
  }

  _processPhotos() {
    if (!this.photos.length) {
      return this._getPhotos(this.processPhotos);
    }

    const deferred = $.Deferred();

    this.$galleryImages.each((i, el) => {
      if (!this.photos[i]) {
        return;
      }

      const photo = this.photos[i];

      const $link = $(`<a
        class="${classes.instagram.image} ${classes.instagram.hidden}"
        target="_blank"
        href="${ photo.link }"></a>`);
      const $photo = $(`<img src="${ photo.images.standard_resolution.url }">`);

      /*
      TODO:
        - Retina suppport
          - Update pxu-instagram-squared to return large format
          - Get appropriate size of image for if is large/small

        const lowResolution = photo.images.low_resolution;
        const standardResolution = photo.images.standard_resolution;
        if (standardResolution.width >= (lowResolution.width * 2)) {
          $photo.attr('srcset', `${ srcLarge } 2x`);
        } else {
          $photo.attr('src', `${ srcLarge }`);
        }
      */

      $photo.one('load', () => {
        $link.append($photo).appendTo($(el));

        if (i === this.photos.length - 1) {
          deferred.resolve();
        }
      });
    });

    return deferred.done(() => {
      this._renderPhotos();
    });
  }

  _renderPhotos() {
    // Lock height to prevent screen jumping
    this.$gallery.height(this.$gallery.outerHeight(true));

    this.$placeholders.fadeOut().promise().then(() => {
      this.$placeholders.each((i, el) => {
        const $el = $(el);
        const $imageContainer = $el.parent();
        const $slide = $el.parents(selectors.gallery.slide);
        const hasImage = $el.next().length;

        // Remove placeholder
        $el.remove();

        // If there is no element after the placeholder, remove container
        if (!hasImage) {
          $imageContainer.remove();
        }

        // Remove slide if no images or placeholder
        if (!$slide.children().length) {
          if (this.flickity) {
            this.flickity.remove($slide[0]);
            this.flickity.resize();
          } else {
            $slide.remove();
          }
        }
      });

      this.$gallery
        .css('height', '')
        .find(`.${classes.instagram.hidden}`)
        .removeClass(classes.instagram.hidden);

      if (this.flickity) {
        this.flickity.reloadCells();
      }
    });
  }
}
