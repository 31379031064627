import $ from 'jquery';

export default class Popover {
  constructor(reference, attachment, options = {}) {
    const defaults = {
      animateHeight: true,
      fixed: true,
      attach: 'top',
      boundaryElement: null,
    };

    this.reference = reference;
    this.attachment = attachment;
    this.$attachment = $(attachment);
    this.options = $.extend(
      {},
      defaults,
      options,
    );

    this.onClick = this._onClick.bind(this);

    this.deferred = {
      action: 'none',
      object: $.Deferred(),
    };
    this.isEnabled = false;
    this.isOpen = false;
    this.referencePoint = 0;
    this.viewportHeight = 0;
  }

  get enabled() {
    return this.isEnabled;
  }

  enable() {
    if (this.isEnabled) {
      return;
    }

    this.attachment.addEventListener('click', this.onClick);
    this.reference.classList.add('popover-reference');
    this.attachment.classList.add('popover-attachment');

    if (this.options.fixed) {
      this.reference.classList.add('popover-reference-fixed');
      this.attachment.classList.add('popover-attachment-fixed');
    }

    this.isEnabled = true;
  }

  disable(force = false) {
    if (!this.isEnabled) {
      return $.Deferred.reject().promise();
    }

    if (this.isOpen) {
      return this.close(force).then(() => {
        this.attachment.removeEventListener('click', this.onClick);
        this.reference.classList.remove('popover-reference');
        this.attachment.classList.remove('popover-attachment');

        if (this.options.fixed) {
          this.reference.classList.remove('popover-reference-fixed');
          this.attachment.classList.remove('popover-attachment-fixed');
        }
        this.isEnabled = false;
      });
    }
    this.isEnabled = false;

    this.attachment.removeEventListener('click', this.onClick);
    this.reference.classList.remove('popover-reference');
    this.attachment.classList.remove('popover-attachment');

    if (this.options.fixed) {
      this.reference.classList.remove('popover-reference-fixed');
      this.attachment.classList.remove('popover-attachment-fixed');
    }

    this.attachment.setAttribute('style', '');

    return $.Deferred().resolve().promise();
  }

  open(force = false) {
    const deferred = $.Deferred();

    if (!this.isEnabled) {
      return $.Deferred().reject().promise();
    }

    if (
      this.deferred.action === 'open'
      && this.deferred.object.state() === 'pending'
    ) {
      return $.Deferred().reject().promise();
    }

    if (this.deferred.object.state() === 'pending') {
      this.deferred.object.reject();
    }

    this.deferred = {
      action: 'open',
      object: deferred,
    };
    this.isOpen = true;

    if (this.options.fixed) {
      this._fixToReference();
    } else {
      this._absoluteToReference();
    }

    this.$attachment.revealer('show', force).one('trend', () => {
      if (deferred.state() !== 'pending') {
        return;
      }

      this.reference.classList.add('popover-open');
      this.attachment.classList.add('popover-open');

      if (this.options.animateHeight) {
        const height = this.attachment.getBoundingClientRect().height;

        this.attachment.style.height = `${height}px`;
      }

      deferred.resolve();
    });

    return deferred.promise();
  }

  close(force = false) {
    const deferred = $.Deferred();

    if (!this.isEnabled) {
      return $.Deferred().reject().promise();
    }

    if (
      this.deferred.action === 'close'
      && this.deferred.object.state() === 'pending'
    ) {
      return $.Deferred().reject().promise();
    }

    if (this.deferred.object.state() === 'pending') {
      this.deferred.object.reject();
    }

    this.deferred = {
      action: 'close',
      object: deferred,
    };
    this.isOpen = false;

    this.$attachment.revealer('hide', force).one('trend', () => {
      if (deferred.state() !== 'pending') {
        return;
      }

      this.reference.classList.remove('popover-open');
      this.attachment.classList.remove('popover-open');
      this.attachment.setAttribute('style', '');

      deferred.resolve();
    });

    return deferred.promise();
  }

  toggle() {
    if (!this.isEnabled) {
      return $.Deferred().reject().promise();
    }

    if (this.isOpen) {
      return this.close();
    }

    return this.open();
  }

  update() {
    if (!this.isEnabled || !this.isOpen) {
      return $.Deferred().reject().promise();
    }

    const currentHeight = this.attachment.getBoundingClientRect().height;
    this.attachment.style.height = '';

    if (this.options.fixed) {
      this._fixToReference();
    } else {
      this._absoluteToReference();
    }

    if (this.options.animateHeight) {
      const toHeight = this.attachment.getBoundingClientRect().height;

      return this._animateHeight(currentHeight, toHeight);
    }

    this.attachment.style.height = `${currentHeight}px`;

    return $.Deferred().resolve().promise();
  }

  unload() {
    this.disable();
  }

  _onClick(event) {
    event.stopPropagation();
  }

  _fixToReference() {
    const height = window.innerHeight;

    this.attachment.style.position = 'fixed';

    if (!this.viewportHeight) {
      this.referencePoint = this.options.attach === 'top'
        ? this.reference.getBoundingClientRect().top
        : this.reference.getBoundingClientRect().bottom;
      this.viewportHeight = height;
    }

    // If the available document element height is still the same,
    // the iOS or other overlay is changing, so don't change position
    if (this.viewportHeight === height) {
      this.attachment.style.bottom = `${height - this.referencePoint}px`;
      return;
    }

    this.referencePoint = this.options.attach === 'top'
      ? this.reference.getBoundingClientRect().top
      : this.reference.getBoundingClientRect().bottom;
    this.viewportHeight = height;
    this.attachment.style.bottom = `${height - this.referencePoint}px`;
  }

  _absoluteToReference() {
    this.attachment.style.position = 'absolute';
    this.attachment.style.bottom = 0;

    if (this.options.boundaryElement) {
      this._constrainToBoundary();
    }
  }

  _constrainToBoundary() {
    const boundaryRect = this.options.boundaryElement.getBoundingClientRect();
    const boundaryHeight = boundaryRect.height;
    const boundaryTop = boundaryRect.top;

    const currentBottom = this.attachment.style.bottom;

    // We can't get the box shadow of elements, subtract arbitrary value
    // to accomodate box shadows
    this.attachment.style.maxHeight = `${boundaryHeight - 2}px`;
    this.attachment.style.transition = 'none';
    this.attachment.style.bottom = 0;
    this.attachment.classList.add('popover-open');

    const attachmentRect = this.attachment.getBoundingClientRect();
    const attachmentTop = attachmentRect.top;

    let offset = 0;

    if (boundaryTop > attachmentTop) {
      offset = boundaryTop - attachmentTop;
    }

    this.attachment.classList.remove('popover-open');
    this.attachment.style.bottom = currentBottom;
    this.attachment.style.transition = '';
    this.attachment.style.bottom = `-${offset}px`;
  }

  _animateHeight(from, to) {
    const deferred = $.Deferred();

    if (this.deferred.object.state() === 'pending') {
      return $.Deferred().reject().promise();
    }

    this.deferred = {
      action: 'animate-height',
      object: deferred,
    };
    this.attachment.style.height = `${from}px`;

    window.requestAnimationFrame(() => {
      if (deferred.state() !== 'pending') {
        return;
      }

      $(this.attachment).one('transitionend', () => deferred.resolve());
      this.attachment.style.height = `${to}px`;

      // Automatically resolve after 1 second if no transition is applied
      setTimeout(() => {
        if (deferred.state() === 'pending') {
          deferred.resolve();
        }
      }, 2500);
    });

    return deferred.promise();
  }
}
