import MobileScrollers from '../components/MobileScrollers';
import PriceManager from '../components/PriceManager';

export default class Testimonials extends MobileScrollers {
  constructor(container) {
    super(container);
    this.priceManager = new PriceManager(this.el);
    this.priceManager.updateAll();

    this._checkBreakpoint = this._checkBreakpoint.bind(this);

    window.addEventListener('resize', this._checkBreakpoint)

    const cellAlign = this.el.querySelectorAll('.testimonial').length > 2
      ? 'left'
      : 'center';

    if (this.el.nextElementSibling && !this.el.nextElementSibling.hasChildNodes()) {
      const lastTestimonial = this.el.querySelector('.testimonials:last-of-type');
      lastTestimonial.style.borderStyle = 'none';
    }

    this.init('[data-testimonials]', {
      cellAlign,
    });

    this.applyElementHeight('.testimonial');
  }

  _checkBreakpoint() {
    this.applyElementHeight('.testimonial');
  }
}
