import { selectors } from '../Constants';
import ImageWithTextOverlay from './ImageWithTextOverlay';
import Popover from '../components/Popover';

export default class ShareBlock extends ImageWithTextOverlay {
  constructor(container) {
    super(container);

    this.popover = new Popover({
      content: this.el.querySelector(selectors.popover.contents.share),
      trigger: this.el.querySelector(selectors.popover.triggers.share),
      perfectCenter: true,
    });
  }

  onSectionUnload() {
    // Unload event binding in ImageWithTextOverlay
    super.onSectionUnload();

    this.popover.destroy();
  }
}
