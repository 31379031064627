import { classes } from '../Constants';

export default class ScrollLock {
  constructor() {
    this.body = document.body;
    this.html = document.querySelector('html');
    this.blockScroll = this._blockScroll.bind(this);
  }

  lock() {
    this.html.classList.add(classes.scrollLock.locked);
    this.body.style.top = -1 * window.pageYOffset;
    this.body.addEventListener('scroll', this.blockScroll, false);
  }

  unlock() {
    this.html.classList.remove(classes.scrollLock.locked);
    this.body.style.top = '';
    this.body.removeEventListener('scroll', this.blockScroll, false);
  }

  _blockScroll(event) {
    event.preventDefault();
  }
}

