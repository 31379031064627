import $ from 'jquery';
import { classes } from '../../Constants';
import StaticStory from '../../sections/StaticStory';

export default class HeaderStory {
  constructor(options) {
    this.$document = $(document);

    const defaults = {
      $mainHeader: null,
      $drawer: null,
      $toggle: null,
      storyHandle: null,
    };

    this.config = $.extend({}, defaults, options);

    this.story = null;
  }

  init() {
    const storyHandle = this.config.storyHandle;
    const storyTemplate = 'story.ajax';

    $.ajax({
      url: `/pages/${storyHandle}`,
      data: `view=${storyTemplate}`,
      dataType: 'html',
      success: (data) => {
        const el = this.config.$drawer[0];
        this.config.$toggle.parent().removeClass(classes.drawerToggles.disabled);
        this.config.$drawer.html(data);

        this.story = new StaticStory({ el });
        this.$document.trigger('check-object-fit', { el });
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.warn(`Unable to retrieve Story with error: ${errorThrown}`);
      },
    });
  }

  unload() {
    if (this.story) {
      this.story.onSectionUnload();
    }
  }
}

