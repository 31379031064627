// jQuery plugins
import './globals/jquery';
import './globals/jquery-plugins';
import 'jquery-trend'; // eslint-disable-line
import 'jquery-revealer'; // eslint-disable-line

// Responsive Images
import 'intersection-observer'; // eslint-disable-line
import rimg from 'rimg-shopify'; // eslint-disable-line

// Helpers
import ObjectFitPolyfill from './helpers/ObjectFitPolyfill';
import './helpers/NodelistForeachPolyfill';
import Newsletter from './helpers/Newsletter';
import './helpers/formElements';

// Template imports
import Page from './templates/Page';
import SearchPage from './templates/SearchPage';
import initAddresses from './templates/account';

// Section imports
import Sections from './Sections';
import DynamicBlogPosts from './sections/DynamicBlogPosts';
import DynamicInstagramFeed from './sections/DynamicInstagramFeed';
import DynamicListCollections from './sections/DynamicListCollections';
import DynamicProduct from './sections/DynamicProduct';
import DynamicSlideshow from './sections/DynamicSlideshow';
import DynamicTwitterFeed from './sections/DynamicTwitterFeed';
import FeaturedProducts from './sections/FeaturedProducts';
import ImageWithTextOverlay from './sections/ImageWithTextOverlay';
import PxsMap from 'pxs-map'; // eslint-disable-line
import StaticAnnouncementBar from './sections/StaticAnnouncementBar';
import StaticCart from './sections/StaticCart';
import StaticCollectionPages from './sections/StaticCollectionPages';
import StaticFaq from './sections/StaticFaq';
import StaticGiftCard from './sections/StaticGiftCard';
import StaticHeader from './sections/StaticHeader';
import StaticProductPages from './sections/StaticProductPages';
import StaticProductRecommendations from './sections/StaticProductRecommendations';
import StaticStory from './sections/StaticStory';
import Testimonials from './sections/Testimonials';
import VideoWithText from './sections/VideoWithText';

import { BannerManager } from './components/BannerManager';

const data = JSON.parse(document.body.querySelector('[data-theme-data]').innerHTML);

new BannerManager({
  target: document.body.querySelector('[data-banners]'),
  dismissIcon: data.dismiss_icon,
});

rimg.init();

const sections = new Sections();
sections.register('dynamic-blog-posts', section => new DynamicBlogPosts(section));
sections.register('dynamic-featured-collection', section => new FeaturedProducts(section));
sections.register('dynamic-image-with-text-overlay', section => new ImageWithTextOverlay(section));
sections.register('dynamic-instagram-feed', section => new DynamicInstagramFeed(section));
sections.register('dynamic-list-collections', section => new DynamicListCollections(section));
sections.register('dynamic-product', section => new DynamicProduct(section));
sections.register('dynamic-slideshow', section => new DynamicSlideshow(section));
sections.register('dynamic-testimonials', section => new Testimonials(section));
sections.register('dynamic-twitter-feed', section => new DynamicTwitterFeed(section));
sections.register('dynamic-video-with-text', section => new VideoWithText(section));
sections.register('pxs-announcement-bar', section => new StaticAnnouncementBar(section));
sections.register('pxs-map', section => new PxsMap(section));
sections.register('static-cart', section => new StaticCart(section));
sections.register('static-collection-pages', section => new StaticCollectionPages(section));
sections.register('static-faq', section => new StaticFaq(section));
sections.register('static-gift-card', section => new StaticGiftCard(section));
sections.register('static-header', section => new StaticHeader(section));
sections.register('static-product-pages', section => new StaticProductPages(section));
sections.register('static-product-recommendations', section => new StaticProductRecommendations(section));
sections.register('static-story', section => new StaticStory(section));
sections.register('static-testimonials', section => new Testimonials(section));

new SearchPage();
new Page();
new ObjectFitPolyfill();
new Newsletter();

initAddresses()
  .catch(error => console.error(error));
