import Cart from '../components/cart/Cart';

export default class StaticCart {
  constructor(section) {
    this.el = section.el;
    this.moneyFormat = section.data.money_format;
    this.shipping = section.data.shipping;
    this.quantityText = section.data.quantity_text;
    this.cartEl = this.el.querySelector('[data-cart]');

    this._init();
  }

  _init() {
    this.cart = new Cart(
      this.cartEl,
      {
        moneyFormat: this.moneyFormat,
        shipping: this.shipping,
      },
    );
  }

  onSectionUnload() {
    this.cart.unload();
  }
}
