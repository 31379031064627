import rimg from 'rimg-shopify'; // eslint-disable-line
import AsyncView from 'shopify-asyncview';
import FeaturedProducts from './FeaturedProducts';

export default class StaticProductRecommendations {
  constructor(section) {
    this.section = section;
    this.productId = section.data.productId;
    this.limit = section.data.settings.limit;
    this.products = null;

    this.recommendationContainer = document.querySelector('[data-product-recommendations]');

    this.recommendUrl = `/recommendations/products?section_id=static-product-recommendations&limit=${this.limit}&product_id=${this.productId}`;

    this._loadRecommendations();
  }

  _loadRecommendations() {
    AsyncView.load(
      this.recommendUrl,
    ).done(({ html }) => {
      this.recommendationContainer.innerHTML = html;

      rimg.watch(this.recommendationContainer);

      if (window.Shopify && window.Shopify.PaymentButton) {
        window.Shopify.PaymentButton.init();
      }

      this.products = new FeaturedProducts(this.section);
    });
  }

  onSectionUnload() {
    if (this.products) {
      this.products.onSectionUnload();
    }
  }
}
