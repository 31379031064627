import $ from 'jquery';
import PxsAnnouncementBar from 'pxs-announcement-bar';

export default class StaticAnnouncementBar {
  constructor(section) {
    // Load in PxsAnnouncementBar to future proof dependency updates
    this.announcementBar = new PxsAnnouncementBar(section);
    $(window).trigger('announcement-bar');
  }

  onSectionUnload() {
    this.announcementBar.onSectionUnload();
  }
}
