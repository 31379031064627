import $ from 'jquery';

export default class HeaderNotification {
  constructor(options) {
    const defaults = {
      $drawer: null,
      $mainHeader: null,
      showNotification: false,
    };

    this.config = $.extend({}, defaults, options);
    this.$notificationDismiss = this.config.$mainHeader.find('[data-notification-dismiss]');
    this.notificationKey = 'pxu_notification_seen';

    this.dismissNotification = this._dismissNotification.bind(this);
    this._bindEvents();
  }

  _bindEvents() {
    this.$notificationDismiss.on('click.header-drawers', (event) => {
      event.preventDefault();

      this._dismissNotification();
      this._hideNotification();
    });
  }

  unload() {
    this.$notificationDismiss.off('click.header-drawers');
  }

  openNotification(skipClose = true, forceOpen = false) {
    let showNotification = true;
    const isDismissed = this.isNotificationDismissed();

    // Only attempt to open notification if is enabled
    if (!this.config.showNotification || isDismissed) {
      showNotification = false;
    }

    // Force notification open
    if (forceOpen && !this.config.$drawer.revealer('isVisible')) {
      showNotification = true;
    }

    if (!showNotification) {
      return;
    }

    this.config.$mainHeader.trigger('header-drawers:open', {
      drawer: 'notification',
      skipClose,
    });
  }

  isNotificationDismissed() {
    // Amount of days notification should be dismissed for
    const lastSeen = this._lastSeenNotification();
    const dismissalPeriod = 30;

    const now = new Date().getTime();
    const diff = Math.round((now - lastSeen) / 1000);

    // Days since notification was dismissed
    const elapsedDays = Math.floor(diff / (24 * 60 * 60));

    return dismissalPeriod > elapsedDays;
  }

  /**
   * When the notification last seen.
   *
   * @returns {number}
   * @private
   */
  _lastSeenNotification() {
    try {
      return localStorage.getItem(this.notificationKey) || 0;
    } catch (error) {
      console.warn(error);
      return 0;
    }
  }

  _setNotificationTime(timestamp) {
    try {
      localStorage.setItem(this.notificationKey, timestamp);
    } catch (error) {
      console.warn(error);
    }
  }

  _dismissNotification() {
    if (this.isNotificationDismissed()) {
      return;
    }

    const now = new Date().getTime();
    this._setNotificationTime(now);
  }

  _hideNotification() {
    this.config.$mainHeader.trigger('header-drawers:close', {
      drawer: 'notification',
    });
  }
}
