import GroupedContent from 'grouped-content';
import fitvids from 'fitvids';

export default class RichText {
  constructor(el, layout = 'tabs') {
    this.el = el;

    fitvids();
    this.groupedContent = new GroupedContent(this.el, { layout, intelliparse: false });

    this._initExternalLinks(el);
  }

  unload() {
    this.groupedContent.unload();
  }

  /**
   * Open links within an RTE field in a new window if they point to external domains
   *
   * @private
   */
  _initExternalLinks(el){
    const anchors = $(el).find('a[href^="http"]').filter((i, el) => {
      return el.href.indexOf(location.hostname) === -1;
    });

    anchors.attr('target', '_blank');
  }
}
