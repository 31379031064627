import $ from 'jquery';
import $script from 'scriptjs';

export default function initAddresses() {
  const deferred = $.Deferred();

  if (!$('[data-account-template="addresses"]').length) {
    deferred.resolve();
  } else {
    const $themeScripts = $('[data-scripts]');

    $script($themeScripts.data('shopify-common'), () => {
      $script($themeScripts.data('shopify-customer'), () => {
        const id = $('[data-address-id]').data('address-id');
        // Initiate provinces for the New Address form
        new Shopify.CountryProvinceSelector(
          `customer-addr-${id}-country`,
          `customer-addr-${id}-province`,
          {
            hideElement: `customer-addr-${id}-province-wrapper`,
          },
        );

        deferred.resolve();
      });
    });
  }

  return deferred.promise();
}
