import $ from 'jquery';
import { selectors } from '../Constants';

export default class VideoWithText {
  constructor(container) {
    this.el = container.el;
    this.videoEl = this.el.querySelector(selectors.videoBlock.videoEl);

    this.playButton = this.videoEl.querySelector(selectors.videoBlock.playButton);
    this.video = this.videoEl.querySelector('iframe');

    this.$blockingLayer = $(this.videoEl.querySelector(selectors.videoBlock.blockingLayer));

    this.playVideo = this._playVideo.bind(this);
    this.onVideoLoad = this._onVideoLoad.bind(this);

    this._bindEvents();
  }

  onSectionUnload() {
    this.video.removeEventListener('load', this.onVideoLoad);

    if (this.playButton) {
      this.playButton.removeEventListener('click', this.playVideo);
    }
  }

  _bindEvents() {
    this.video.addEventListener('load', this.onVideoLoad);

    if (this.playButton) {
      this.playButton.addEventListener('click', this.playVideo);
    }
  }

  _playVideo() {
    const src = this.video.getAttribute('src');
    const newSrc = `${src}&autoplay=1`;

    this.playButton.classList.add('loading');
    this.playButton.setAttribute('disabled', '');
    this.video.setAttribute('src', newSrc);
  }

  _onVideoLoad() {
    if (this.video.getAttribute('src').indexOf('autoplay') === -1) {
      return;
    }

    setTimeout(() => {
      this.$blockingLayer.addClass('hidden').one('trend', () => {
        this.$blockingLayer.addClass('disabled');
      });
    }, 600);
  }
}
