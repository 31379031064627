import $ from 'jquery';
import Popper from 'popper.js';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Layout from '../Layout';
import { classes, selectors } from '../Constants';

export default class Popover {
  constructor(options = {}) {
    this.content = options.content;
    this.trigger = options.trigger;
    this.perfectCenter = options.perfectCenter || false;
    this.$content = $(this.content);
    this.$trigger = $(this.trigger);
    this.$window = $(window);
    this.$body = $(document.body);
    this.popper = null;
    this.$exception = this.$body.find('[data-popover-exception]');

    this.onPopoverOpen = this._onPopoverOpen.bind(this);
    this.onPopoverClose = this._onPopoverClose.bind(this);
    this.toggle = this._togglePopover.bind(this);
    this.computeStyle = this._computeStyle.bind(this);
    this.onCreate = this._onCreate.bind(this);

    const defaultCallbacks = {
      onShow: () => {},
      onHide: () => {},
    };

    this.callbacks = $.extend({}, defaultCallbacks, options.callbacks);

    this.defaultOptions = {
      placement: 'top',
      modifiers: {
        preventOverflow: {
          order: 700,
          enabled: true,
          priority: ['top', 'bottom', 'right', 'left'],
          boundariesElement: 'window',
          padding: 60,
        },
        computeStyle: {
          order: 750,
          gpuAcceleration: false,
          fn: this.computeStyle,
          x: 'top',
        },
        flip: {
          enabled: false,
        },
        arrow: {
          enabled: false,
        },
        hide: {
          enabled: false,
        },
        keepTogether: {
          enabled: false,
        },
      },
      onCreate: this.onCreate,
    };

    this.options = $.extend(true, {}, this.defaultOptions, options.popperOptions);

    this._initPopover();
    this._bindEvents();
  }

  destroy() {
    this._unbindEvents();
    this.hidePopover();
    this.popper.destroy();
    this.popper = null;

    this.$content
      .removeClass(classes.popover.created)
      .css({
        height: '',
        top: '',
        right: '',
        bottom: '',
        left: '',
        willChange: '',
      });
  }

  update() {
    if (!this.popper) {
      return;
    }

    this.popper.update();
  }

  _initPopover() {
    this.popper = new Popper(this.trigger, this.content, this.options);
  }

  _togglePopover() {
    if (this.$trigger.parent().hasClass(classes.popover.buttonActive)) {
      this.hidePopover();
    } else {
      this._showPopover();
    }
  }

  /**
   * Create and open a Popper
   */
  _showPopover() {
    this.$window.trigger('popover-open', [this.trigger]);
    this.$body.addClass(classes.popover.active);
    this.callbacks.onShow();

    // Disable scrolling, except in popover
    if (Layout.isBreakpoint('XS') || Layout.isBreakpoint('S')) {
      disableBodyScroll(this.content.children[0]);
    }

    this.$trigger
      .parent()
      .addClass(classes.popover.buttonActive)
      .one('trend', () => {
        this.popper.update();
        this.$trigger.ariaExpanded(true);
        this.$content
          .ariaHidden(false)
          .revealer('show').one('revealer-show', () => this.popper.update());
      });
  }

  /**
   * Close and remove Popper
   */
  hidePopover() {
    this.callbacks.onHide();

    // Re-enable scrolling
    enableBodyScroll(this.content.children[0]);

    this.$content
      .removeClass(classes.popover.scrolls)
      .ariaHidden(true)
      .revealer('hide')
      .one('revealer-animating', () => {
        this.$body.removeClass(classes.popover.active);
        this.$trigger
          .ariaExpanded(false)
          .parent()
          .removeClass(classes.popover.buttonActive);
        this.$window.trigger('popover-close', [this.trigger]);
      });
  }

  _bindEvents() {
    this.trigger.addEventListener('click', this.toggle);
    this.$window.on('popover-open', this.onPopoverOpen);
    this.$window.on('popover-close', this.onPopoverClose);
  }

  _unbindEvents() {
    this.trigger.removeEventListener('click', this.toggle);
    this.$window.off('popover-open', this.onPopoverOpen);
    this.$window.off('popover-close', this.onPopoverClose);
  }

  _onCreate() {
    this.$content.addClass(classes.popover.created);
  }

  /**
   * Get style information from Popover for placement logic, and visual enhancement
   *
   * @param baseHeight
   * @returns {{height: number, innerHeight: number, minLeft: Number}}
   * @private
   */
  _popoverInfo(baseHeight) {
    const { left, paddingTop, paddingBottom, marginTop, marginBottom } = getComputedStyle(this.content);
    const minLeft = parseInt(left, 10);
    const height = baseHeight - parseInt(marginBottom, 10) - parseInt(marginTop, 10);
    const innerHeight = height - parseInt(paddingBottom, 10) - parseInt(paddingTop, 10);

    return {
      height,
      innerHeight,
      minLeft: !isNaN(minLeft) ? minLeft : 0,
    };
  }

  /**
   * Determine if a Popover's contents exceeds its possible height, and apply a scrolling class
   *
   * @param innerHeight
   * @returns {boolean}
   * @private
   */
  _popoverScrolls(innerHeight, isException) {
    const $children = $(this.content.querySelector('div')).children();

    if (isException) {
      return false;
    }

    let childrenHeight = 0;
    $children.each((i, el) => {
      childrenHeight += $(el).outerHeight(true);
    });

    // Add 10px for "safety"
    return Math.ceil(childrenHeight) + 10 > innerHeight;
  }

  _perfectCenter() {
    return (this.$window.width() - this.$content.outerWidth()) / 2;
  }

  /**
   * Sets Popper placement
   *
   * @param data
   * @returns {*}
   * @private
   */
  _computeStyle(data) {
    if (!data) {
      return {};
    }

    const { popper } = data.offsets;
    const { height, innerHeight, minLeft } = this._popoverInfo(popper.height);
    const popoverScrolls = this._popoverScrolls(innerHeight, this.$exception.length);

    // If needing to perfectly center, use custom logic
    // Popper will attach to center by the trigger, but doesn't account for translateX
    const left = this.perfectCenter
      ? Math.round(this._perfectCenter())
      : Math.max(Math.round(popper.left), minLeft);
    const top = Math.round(popper.top);

    this.$content.toggleClass(classes.popover.scrolls, popoverScrolls);

    const styles = {
      position: popper.position,
      left,
      top,
      height,
    };

    data.styles = $.extend(true, {}, data.styles, styles);

    return data;
  }

  /**
   * Toggle popover trigger as hidden if other popovers open
   *
   * @param event
   * @param trigger
   * @private
   */
  _onPopoverOpen(event, trigger) {
    if (this.trigger === trigger) {
      return;
    }

    this.$trigger.parent().addClass(classes.popover.buttonHidden);
  }

  /**
   * Toggle popover trigger as visible if other popovers closed
   *
   * @param event
   * @param trigger
   * @private
   */
  _onPopoverClose(event, trigger) {
    if (this.trigger === trigger) {
      return;
    }

    this.$trigger.parent().removeClass(classes.popover.buttonHidden);
  }
}
