const selectors = {
  header: {
    main: '[data-header-main]',
    mobile: '[data-header-mobile]',
    drawerToggle: '[data-header-drawer-toggle]',
    drawerToggleParent: '[data-header-toggle-parent]',
    drawerToggleAttr: 'data-header-drawer-toggle',
    drawerItemAttr: 'data-header-drawer',
    navigation: '[data-header-navigation]',
    actions: '[data-header-actions]',
    utilities: '[data-header-utilities]',
    currencyConverter: '[name=currencies]',
    converterParent: '[data-header-currency-converter]',
  },
  announcementBar: {
    main: '.pxs-announcement-bar',
  },
  drawers: {
    menu: {
      navigation: '[data-header-drawer-navigation]',
      converterParent: '[data-header-drawer-currency-converter]',
    },
    search: {
      field: '[data-header-search]',
    },
  },
  navmenu: {
    submenu: '[data-navmenu-submenu]',
    trigger: '[data-navmenu-trigger]',
    depthOne: '.navmenu-link-depth-1',
  },
  cardsPlayer: {
    card: '[data-cards-player-card]',
    cardVisual: '[data-cards-player-card-visual]',
    cardContent: '[data-cards-player-card-content]',
  },
  collectionUtils: {
    utils: '[data-collection-utils]',
    narrow: '[data-collection-utils-narrow]',
    wide: '[data-collection-utils-wide]',
  },
  collectionPopover: {
    filterItem: '[data-collection-filter] a',
    filterItemAdvanced: '[data-collection-filter-advanced] a',
    sortByItem: '[data-collection-sortby]',
  },
  popover: {
    trigger: '[data-popover-trigger]',
    target: '[data-popover-target]',
    content: '[data-popover-content]',
    triggers: {
      collectionFilter: '[data-popover-trigger="filters"]',
      collectionSortBy: '[data-popover-trigger="sort-by"]',
      atcSteps: '[data-popover-trigger="atc-steps"]',
      atcToggle: '[data-popover-toggle]',
      share: '[data-popover-trigger="share"]',
    },
    contents: {
      collectionFilter: '[data-popover-content="filters"]',
      collectionSortBy: '[data-popover-content="sort-by"]',
      atcStepForm: '[data-popover-content="atc-step-form"]',
      atcStepCart: '[data-popover-content="atc-step-cart"]',
      share: '[data-popover-content="share"]',
    },
  },
  product: {
    el: '[data-product]',
    form: {
      el: '[data-product-form]',
      variants: '[data-variants]',
      productOption: '[data-product-option]',
      quantity: '[data-quantity]',
      atc: '[data-product-form-atc]',
      submit: '[data-product-submit]',
      submitText: '[data-product-text]',
    },
    item: {
      el: '[data-product-item]',
      urlAttr: 'data-product-item-url',
      idAttr: 'data-product-item-id',
    },
    quickshop: {
      data: '[data-quickshop-data]',
      error: '[data-quickshop-error]',
      errorAttr: 'data-quickshop-error',
      trigger: '[data-product-quickshop-trigger]',
      contents: '[data-product-quickshop]',
      selectorAttr: 'data-product-item-quickshop',
    },
    atc: '[data-product-atc]',
    description: '[data-product-description]',
    mainContent: '[data-product-main-content]',
    gallery: '[data-product-gallery]',
    slideshowImages: '[data-product-slideshow-images]',
    slideshowImage: '[data-product-slideshow-image]',
    slideshowThumbnails: '[data-product-slideshow-thumbnails]',
    slideshowThumbnailsWrapper: '[data-product-slideshow-thumbnails-wrapper]',
    slideshowThumbnailsPrev: '[data-product-slideshow-thumbnails-prev]',
    slideshowThumbnailsNext: '[data-product-slideshow-thumbnails-next]',
    slideshowThumbnail: '[data-product-slideshow-thumbnail]',
    slideshowThumbnailSelected: '.product-slideshow-thumbnail-selected',
  },
  slideshow: {
    container: '[data-slideshow]',
    slide: '[data-slideshow-slide]',
    slideImage: '[data-slide-image]',
    slideContent: '[data-slide-content]',
    linkButton: '[data-slideshow-button]',
    pagination: '[data-slideshow]',
    paginationNext: '[data-slideshow="next"]',
    paginationPrev: '[data-slideshow="prev"]',
  },
  gallery: {
    container: '[data-gallery]',
    slide: '[data-gallery-slide]',
  },
  twitter: {
    container: '[data-twitter-content]',
    tweet: '[data-tweet-content]',
  },
  instagram: {
    container: '[data-gallery]',
    galleryImage: '.gallery-image',
    placeholder: '.placeholder-svg',
  },
  videoBlock: {
    videoEl: '[data-video-block]',
    blockingLayer: '[data-video-overlay]',
    playButton: '[data-video-play]',
  },
  quantity: {
    el: '[data-quantity]',
    submitEl: '[data-quantity-submit]',
    decrement: '[data-quantity-decrement]',
    increment: '[data-quantity-increment]',
    element: '[data-quantity-element]',
    input: '[data-quantity-input]',
  },
  newsletter: {
    formPosted: '[data-newsletter-submitted]',
  },
  cart: {
    el: '[data-cart]',
    form: '[data-cart-form]',
    expand: '[data-cart-expand]',
    expandable: '[data-cart-expandable]',
    totals: '[data-cart-totals]',
    subtotal: '[data-cart-subtotal]',
    submit: '[data-cart-submit]',
    otherItemsButton: '[data-cart-otheritems-button]',
    atcSuccess: '[data-product-atc-success]',
    item: {
      el: '[data-cart-item]',
      remove: '[data-cart-item-remove]',
      price: '[data-cart-item-price]',
      total: '[data-cart-item-total]',
      slide: '[data-cart-item-column-slide]',
    },
    note: '[data-cart-note]',
    shipping: {
      el: '[data-cart-shipping]',
      response: '[data-cart-shipping-response]',
      message: '[data-cart-shipping-message]',
      rates: '[data-cart-shipping-rates]',
      submit: '[data-cart-shipping-submit]',
    },
  },
  price: {
    compareAt: '[data-price-compare-at]',
    price: '[data-price]',
  },
  password: {
    modal: '[data-password-modal]',
    trigger: '[data-password-modal-trigger]',
    errors: '[data-password-form-error]',
  },
  modal: {
    wrapper: '[data-modal-wrapper]',
  },
};

const classes = {
  header: {
    sticky: 'site-header-sticky',
    scrolled: 'site-header-scrolled',
    bordered: 'site-header-bordered',
  },
  scrollLock: {
    locked: 'scroll-lock',
  },
  navigation: {
    expanded: 'site-navigation-layout-expanded',
    collapsed: 'site-navigation-layout-drawer',
  },
  drawerVisibility: {
    general: 'site-header-drawer-visible',
    account: 'site-header-account-visible',
    notification: 'site-header-notification-visible',
    menu: 'site-header-menu-visible',
    cart: 'site-header-cart-visible action-drawer-visible',
    story: 'site-header-story-visible action-drawer-visible',
    search: 'site-header-search-visible action-drawer-visible',
  },
  drawerToggles: {
    active: 'has-active-toggle',
    disabled: 'is-disabled-toggle',
  },
  navmenu: {
    parentActive: 'navmenu-item-active',
  },
  objectFit: {
    noObjectFit: 'no-object-fit',
  },
  cardsPlayer: {
    player: 'cards-player',
    deck: 'cards-player-deck',
    hand: 'cards-player-hand',
    content: 'cards-player-content',
    visualFirst: 'cards-player-card-visual-first',
    repositioning: 'cards-player-card-repositioning',
    hintingStart: 'cards-player-card-hinting-start',
    hintingEnd: 'cards-player-card-hinting-end',
  },
  popover: {
    created: 'popover-created',
    listItemActive: 'popover-list-item-active',
    scrolls: 'popover-scrolls',
    active: 'popover-active',
    hideHeader: 'popover-hides-header',
    buttonActive: 'popover-button-active',
    buttonHidden: 'popover-button-hidden',
  },
  product: {
    slideshowImageSelected: 'product-slideshow-image-selected',
    slideshowThumbnailSelected: 'product-slideshow-thumbnail-selected',
    slideshowThumbnailsFlickityEnabled: 'product-slideshow-thumbnails-flickity-enabled',
    quickshop: {
      wrapper: 'product-item-quickshop',
      error: 'quickshop-form-errors',
    },
    atc: {
      static: 'product-atc-static',
      defaultVariant: 'product-atc-defaultvariant',
      popper: 'product-atc-popper',
      formActive: 'product-atc-popper-form-active',
      cartActive: 'product-atc-popper-cart-active',
      loading: 'product-form-atc-loading',
    },
  },
  cart: {
    inline: 'cart-inline',
    static: 'cart-static',
    emptying: 'cart-emptying',
    empty: 'cart-empty',
    loading: 'cart-loading',
    loadingAffectsTotal: 'cart-loading-affects-total',
    reloading: 'cart-reloading',
    expanded: 'cart-expanded',
    item: {
      inlineVisible: 'cart-inline-visible',
      inlineRecentlyAdded: 'cart-inline-recentlyadded',
      loading: 'cart-item-loading',
      totalLoading: 'cart-item-total-loading',
      sliding: 'cart-item-sliding',
    },
    shipping: {
      responseVisible: 'cart-shipping-response-visible',
    },
    expandable: {
      expanded: 'cart-expand-button-expanded',
    },
  },
  instagram: {
    image: 'instagram-image',
    hidden: 'hidden',
  },
  quantity: {
    disabled: 'form-element-disabled',
  },
  form: {
    formElements: {
      el: 'form-element',
      disabled: 'form-element-disabled',
    },
  },
};

const events = {
  cart: {
    error: 'cart-error',
    reloadStart: 'cart-reload-start',
    reloadFinish: 'cart-reload-finish',
    loadingStart: 'cart-loading-start',
    loadingFinish: 'cart-loading-finish',
    inlineVisible: 'cart-inline-visible',
    item: {
      loadingStart: 'cart-item-loading-start',
      loadingFinish: 'cart-item-loading-finish',
      removingFinished: 'cart-item-removing-finished',
    },
    note: {
      loadingStart: 'cart-note-loading-start',
      loadingFinish: 'cart-note-loading-finish',
    },
  },
};

export {
  classes,
  selectors,
  events,
};
