import Flickity from 'flickity';
import layout from '../Layout';

export default class FeaturesList {
  constructor(container) {
    this.features = container.el.querySelector('[data-features-list-items]');
    this.flickity = null;

    this._onBreakpointChange = this._onBreakpointChange.bind(this);
    layout.onBreakpointChange(this._onBreakpointChange);

    this._initFlickity();
  }

  _initFlickity() {
    if (!layout.isBreakpoint('XS') && !layout.isBreakpoint('S')) {
      return this._destroyFlickity();
    }

    if (this.flickity) {
      return;
    }

    this.flickity = new Flickity(this.features, {
      cellAlign: 'left',
      contain: true,
      freeScroll: true,
      prevNextButtons: false,
      pageDots: false,
    });
  }

  _destroyFlickity() {
    if (!this.flickity) {
      return;
    }

    this.flickity.destroy();
    this.flickity = null;
  }

  _onBreakpointChange() {
    this._initFlickity();
  }

  onSectionUnload() {
    this._destroyFlickity();
  }
}
