import $ from 'jquery';
import { selectors } from './Constants';
import ScrollLock from './helpers/ScrollLock';
import VanillaModal from 'vanilla-modal';

export default class Modal {
  constructor(callbacks = {}) {
    this.$body = $(document.body);
    this.$window = $(window);

    this.modal = null;
    this.$modal = $(selectors.modal.wrapper);

    this.scrollLock = new ScrollLock();

    // Extend default vanilla-modal callbacks back to instantiator of Modal
    this.defaultCallbacks = {
      onOpen: () => {},
      onClose: () => {},
      onBeforeShow: () => {},
      onBeforeOpen: () => {},
      onBeforeClose: () => {},
    };

    this.callbacks = $.extend({}, this.defaultCallbacks, callbacks);

    this._onOpen = this._onOpen.bind(this);
    this._onBeforeOpen = this._onBeforeOpen.bind(this);
    this._onBeforeShow = this._onBeforeShow.bind(this);
    this._onClose = this._onClose.bind(this);
    this._onBeforeClose = this._onBeforeClose.bind(this);

    this.modalOptions = {
      loadClass: '',
      class: 'modal-loaded',
      onOpen: this._onOpen,
      onClose: this._onClose,
      onBeforeOpen: this._onBeforeOpen,
      onBeforeClose: this._onBeforeClose,
      transitions: false,
    };
  }

  unload() {
    if (!this.modal || !this.modal.isOpen) {
      return;
    }

    this.modal.destroy();
  }

  /**
   * Open a modal with contents from selector
   *
   * @param selector
   * @param handle
   */
  open(selector, handle = 'general') {
    if (this.modal && this.modal.isOpen) {
      return;
    }

    this._addModalClass(handle);
    this.modal = new VanillaModal(this.modalOptions);
    this.modal.open(selector);
  }

  close() {
    this.modal.close();
  }

  isOpen() {
    return this.modal && this.modal.isOpen;
  }

  /**
   * Add a class to the modal for individual styling
   * @param handle
   * @private
   */
  _addModalClass(handle) {
    this.$modal.addClass(`modal-${handle}`);
  }

  /**
   * Remove modal class based on the handle
   * @private
   */
  _removeModalClass() {
    const modalClass = this.$modal.attr('class').match(/modal-[\w-]*\b/);
    if (!modalClass) {
      return;
    }

    this.$modal.removeClass(modalClass[0]);
  }

  _onClose() {
    this._removeModalClass();
    this.$modal.revealer('hide').one('trend', () => {
      this.scrollLock.unlock();
      this.callbacks.onClose();
    });
  }

  _onOpen() {
    this._onBeforeShow();
    this.$modal.revealer('show').one('trend', () => {
      this.scrollLock.lock();
      this.callbacks.onOpen(this.$modal);
    });
  }

  _onBeforeClose() {
    this.callbacks.onBeforeClose();
  }

  _onBeforeShow() {
    this.callbacks.onBeforeShow();
  }

  _onBeforeOpen() {
    this.callbacks.onBeforeOpen();
  }
}
