import $ from 'jquery';
import { classes, selectors } from '../Constants';
import Modal from '../Modal';
import layout from '../Layout';
import PriceManager from '../components/PriceManager';
import ProductLayout from '../components/ProductLayout';
import CartRequests from './cart/CartRequests';

export default class QuickShop {
  constructor(options) {
    this.el = options.el || null;
    this.productUrl = options.url || null;
    this.productID = options.id || null;
    this.trigger = options.trigger || null;
    this.cartRequests = new CartRequests();

    if (
      !this.el
      || !this.productUrl
      || !this.productID
      || !this.trigger
    ) {
      return console.warn('Unable to initiate QuickShop.js');
    }

    this.$window = $(window);
    this.$document = $(document);
    this.$trigger = $(this.trigger);

    // Build up selectors
    this.selectorTag = `${selectors.product.quickshop.selectorAttr}="${this.productID}"`;
    this.selector = `[${this.selectorTag}]`;

    // Default out globals
    this.productLoaded = false;
    this.productEl = null;
    this.$formAtc = null;
    this.$errorMessage = $(`<div ${selectors.product.quickshop.errorAttr} class="${classes.product.quickshop.error}"/>`);
    this.data = null;
    this.modal = null;
    this.priceManager = null;

    this._handleBeforeShow = this._handleBeforeShow.bind(this);
    this._handleOpen = this._handleOpen.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._handleError = this._handleError.bind(this);
    this._resetError = this._resetError.bind(this);
    this._onATCSubmit = this._onATCSubmit.bind(this);
    this._onATCSuccess = this._onATCSuccess.bind(this);

    this._onBreakpointChange = this._onBreakpointChange.bind(this);
    layout.onBreakpointChange(this._onBreakpointChange);
  }

  open() {
    this.trigger.setAttribute('disabled', 'disabled');

    if (!this.productLoaded) {
      $.ajax({
        url: this.productUrl,
        data: 'view=quickshop',
        dataType: 'html',
        success: (data) => {
          const $wrapper = $(`<div class="${classes.product.quickshop.wrapper}" ${this.selectorTag}/>`);
          const $contents = $wrapper.append(data);

          try {
            this.data = JSON.parse($wrapper.find(selectors.product.quickshop.data).text());
          } catch (error) {
            return console.warn(`Quickshop: invalid quickshop data found. ${error.message}`);
          }

          $(this.el).append($contents);

          // Bind productEl to node of contents, which gets moved into Modal
          this.productEl = this.el.querySelector(selectors.product.quickshop.contents);
          this.productLoaded = true;

          this._openQuickShop();
        },
        error: (jqXHR, textStatus, errorThrown) => {
          console.warn(`Unable to Quickshop with error: ${errorThrown}`);
        },
      });
    } else {
      this._openQuickShop();
    }
  }

  unload() {
    this._handleClose();
    layout.offBreakpointChange(this._onBreakpointChange);
  }

  _openQuickShop() {
    const modalCallbacks = {
      onBeforeShow: this._handleBeforeShow,
      onOpen: this._handleOpen,
      onClose: this._handleClose,
    };

    this.modal = new Modal(modalCallbacks);
    this.modal.open(this.selector, 'quickshop');
  }

  _handleBeforeShow() {
    // Set object fit for IE11
    this.$document.trigger('check-object-fit', {
      el: this.productEl,
    });

    // Update prices to current currency
    this.priceManager = new PriceManager(this.productEl, this.data.money_format);
    this.priceManager.updateAll();

    // Set up error handling
    this.$formAtc = $(this.productEl.querySelector(selectors.product.form.atc));

    // Clear errors on variant switch
    this.$window.on('shopify-variants:switch-variant.product-quickshop', this._resetError);

    // Instantiate Product JS
    this.productLayout = new ProductLayout({
      el: this.productEl,
      moneyFormat: this.data.money_format,
      product: this.data.product,
      variantAvailable: this.data.variant_available,
      cartRedirect: this.data.cart_redirect,
      textStrings: this.data.text_strings,
      onATCSubmit: this._onATCSubmit,
      onATCSuccess: this._onATCSuccess,
    });
  }

  _handleError(error) {
    this.$errorMessage.text(error.responseJSON.description);
    this.$formAtc.prepend(this.$errorMessage);
  }

  _handleOpen($modal) {
    $modal.find('a:visible:first').eq(0).focus();

    if (window.Shopify && Shopify.PaymentButton) {
      Shopify.PaymentButton.init();
    }
  }

  _handleClose() {
    if (this.modal) {
      this.productLayout.unload();
      this.modal.unload();
      this.$trigger.removeAttr('disabled').focus();
      this.$window.off('shopify-variants:switch-variant.product-quickshop');
    }
  }

  _onBreakpointChange() {
    if (layout.isBreakpoint('XS') || layout.isBreakpoint('S')) {
      this._handleClose();
    }
  }

  /**
   * Actions to perform when submitting ATC to Shopify
   *
   * @private
   */
  _onATCSubmit() {
    this._resetError();

    this.cartRequests.setHandlers({
      onError: this._handleError,
    });
  }

  _resetError() {
    if (this.$errorMessage.length) {
      this.$errorMessage.remove();
    }
  }

  /**
   * Actions to perform on a successful ATC
   * @private
   */
  _onATCSuccess() {
    this.cartRequests.restoreHandlers();
  }
}
