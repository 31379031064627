import $ from 'jquery';
import { classes } from '../Constants';

export default class ObjectFitPolyfill {
  constructor() {
    this._onSectionEvent = this._onSectionEvent.bind(this);

    /*
      - Fall back to background images Theme Editor for browsers which don't
      support object-fit
      - Trigger to check element loaded in asynchronously
     */
    $(document).on('shopify:section:select', this._onSectionEvent)
               .on('shopify:block:select', this._onSectionEvent)
               .on('check-object-fit', (event, data) => this._checkElement(data));

    this._init(document.body);
  }


  _isObjectFitAvailable() {
    return 'objectFit' in document.documentElement.style;
  }

  _onSectionEvent(event) {
    this._init(event.target);
  }

  _checkElement(data) {
    if (!data.el) {
      return console.warn('No element specified to check for object-fit');
    }

    this._init(data.el);
  }

  _init(el) {
    if (this._isObjectFitAvailable()) {
      return;
    }

    const $items = $('[data-object-fit-item]', el);

    $items.each((i, item) => {
      const $item = $(item);
      const $image = $('img', $item);

      // Don't re-run on processed elements, or if there is no image
      if ($item.hasClass(classes.objectFit.noObjectFit) || !$image.length) {
        return console.warn('Element already has been checked for object fit compatibility');
      }

      const src = $image.attr('src');

      $item
        .css('background-image', `url("${ src }")`)
        .addClass(classes.objectFit.noObjectFit);
    });
  }
}

