import { selectors } from '../Constants';
import QuickShop from './QuickShop';

export default class ProductItem {
  constructor(el) {
    // Scrape product information
    this.productUrl = el.getAttribute(selectors.product.item.urlAttr);
    this.productID = el.getAttribute(selectors.product.item.idAttr);

    this.quickShopTrigger = el.querySelector(selectors.product.quickshop.trigger);
    this.quickShop = null;
    this.openQuickShop = this._onOpenQuickshop.bind(this);

    if (this.quickShopTrigger) {
      this.quickShop = new QuickShop({
        el,
        trigger: this.quickShopTrigger,
        url: this.productUrl,
        id: this.productID,
      });

      this.quickShopTrigger.addEventListener('click', this.openQuickShop);
    }
  }

  _onOpenQuickshop() {
    this.quickShop.open();
  }

  unload() {
    if (this.quickShop) {
      this.quickShop.unload();
      this.quickShopTrigger.removeEventListener('click', this.openQuickShop);
    }
  }
}
