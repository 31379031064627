import $ from 'jquery';
import { classes, events, selectors } from '../../Constants';
import Cart from '../cart/Cart';

export default class HeaderCart {
  constructor(options) {
    const defaults = {
      $mainHeader: null,
      $drawer: null,
      currency: '',
      isMobile: () => {},
    };

    this.config = $.extend({}, defaults, options);
    this.isMobile = this.config.isMobile;

    this.$drawer = this.config.$drawer;
    this.$mainHeader = this.config.$mainHeader;
    this.mainHeader = this.$mainHeader[0];

    this.afterOpen = this._afterOpen.bind(this);
    this.afterClose = this._afterClose.bind(this);

    this.$window = $(window);
    this.$document = $(document);

    this.cart = new Cart(this.$drawer[0].querySelector(selectors.cart.el), {
      moneyFormat: this.config.currency.money_format,
    });

    this._bindEvents();
  }

  unload() {
    this.cart.unload();
  }

  _bindEvents() {
    this.$window.on(`${events.cart.loadingFinish}.header-cart`, (event, data) => {
      this._toggleCartHeight(true);
      this._updateCartCount(data);
    });
    this.$window.on(`${events.cart.item.removingFinished}.header-cart`, () => this._repositionCart());
  }

  _toggleCartHeight(on = true, force = false) {
    if (force || (this.isMobile() && this.$drawer.hasClass('visible'))) {
      this.$drawer.css('height', on ? this.$drawer.outerHeight() : '');
    }
  }

  _repositionCart() {
    if (!this.isMobile() || !this.$drawer.hasClass('visible')) {
      return;
    }

    const drawerHeight = this.$drawer.find(selectors.cart.el).outerHeight();
    const headerRect = this.mainHeader.getBoundingClientRect();

    // Calculate relative offsets
    const fromBottom = drawerHeight;
    const fromTop = window.innerHeight - (headerRect.height + drawerHeight);

    // Get offset types from existing _openHeader action
    const offsetType = this.$mainHeader.data('offset-type');
    const offset = offsetType === 'top' ? fromTop : fromBottom;

    // Move header to new position
    this.$mainHeader.css(offsetType, offset);

    // Set height of drawer to height of new contents
    this.$drawer.css('height', drawerHeight);
  }

  _updateCartCount(data) {
    const cartCount = this.config.$mainHeader.get(0).querySelector('[data-cart-count]');
    const itemCount = data.response.item_count;

    if (cartCount) {
      cartCount.innerHTML = itemCount === 0 ? '' : itemCount;
    }
  }

  _afterClose() {
    this._toggleCartHeight(false, true);
    this.$drawer.off('click.header-cart');
  }

  _afterOpen() {
    this.$drawer.on('click.header-cart', selectors.cart.expand, (event) => {
      const $target = $(event.currentTarget);
      this._toggleCartHeight($target.hasClass(classes.cart.expandable.expanded));
    });
  }
}
