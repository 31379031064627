import $ from 'jquery';
import debounce from 'just-debounce';

import layout from '../../Layout';
import {classes, selectors} from '../../Constants';

export default class MenuDrawer {
  constructor(options) {
    this.$mainHeader = options.$mainHeader;
    this.settings = options.settings;

    this.$window = $(window);
    this.windowWidth = window.innerWidth;
    this.$navigation = this.$mainHeader.find(selectors.header.navigation);
    this.$currencyConverter = this.$mainHeader.find(selectors.header.converterParent);
    this.$actions = this.$mainHeader.find(selectors.header.actions);

    this.isExpandedNav = this.settings.navigation_layout === 'expanded';
    this.events = [];

    // If navigation is 'expanded', collapse into drawer when needed
    if (this.isExpandedNav) {
      this.events.push(this.$window.on('resize.navigation-drawer', debounce(() => this._navigationResize(), 15)));
    }

    this._autoDrawer();
    this._setupDrawer();
  }

  unload() {
    if (this.events.length) {
      this.events.forEach($el => $el.off('.navigation-drawer'));
    }
  }

  _setupDrawer() {
    // Clone Header components into menu drawer
    this.$mainHeader
      .find(selectors.drawers.menu.navigation)
      .html(this.$navigation.children().clone(true, true));

    if (this.$currencyConverter.length) {
      this.$mainHeader
        .find(selectors.drawers.menu.converterParent)
        .html(this.$currencyConverter.children().clone(true, true));
    }
  }

  _navigationResize() {
    const windowWidth = window.innerWidth;
    if (this.windowWidth === window.innerWidth) {
      return;
    }

    this.windowWidth = windowWidth;
    this._autoDrawer();
  }

  _autoDrawer() {
    // Navigation and actions are the same width, but Actions never has its width change
    const navigationWidth = this.$actions.width();
    const innerWidths = [];

    this.$navigation.find('.navmenu-depth-1 > .navmenu-item').each((index, el) => {
      innerWidths.push(Math.ceil($(el).outerWidth(true)));
    });

    const combinedWidths = innerWidths.reduce((a, b) => a + b, 0);
    const navItemsFit = combinedWidths < navigationWidth;
    const isExpandable = this.isExpandedNav && (layout.isBreakpoint('L') || layout.isBreakpoint('XL'));

    this._collapseNavigation(navItemsFit && isExpandable);
  }

  _collapseNavigation(expandNavigation = false) {
    if (expandNavigation) {
      this.$mainHeader
        .removeClass(classes.navigation.collapsed)
        .addClass(classes.navigation.expanded);
    } else {
      this.$mainHeader
        .removeClass(classes.navigation.expanded)
        .addClass(classes.navigation.collapsed)
        .trigger('header-drawers:close', { drawer: 'account' });
    }
  }
}
