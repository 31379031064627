import DeckCase from '../components/DeckCase';

export default class DynamicListCollections {
  constructor(section) {
    this.deckCase = new DeckCase(section.el.querySelector('[data-cards]'));
  }

  onSectionUnload() {
    this.deckCase.onSectionUnload();
  }
}
