import { selectors } from '../../Constants';

export default function getAnnouncementBarHeight() {
  const el = document.querySelector(selectors.announcementBar.main);

  if (!el) {
    return 0;
  }

  const { height } = el.getBoundingClientRect();
  return Math.ceil(height);
}
