import $ from 'jquery';
import { classes, selectors } from '../../Constants';
import layout from '../../Layout';
import getHeaderHeight from './getHeaderHeight';
import getAnnouncementBarHeight from './getAnnouncementBarHeight';

export default class StickyHeader {
  constructor(options) {
    this.settings = options.settings;
    this.$mainHeader = options.$mainHeader;
    this.mainHeader = this.$mainHeader[0];
    this.mobileHeader = document.querySelector(selectors.header.mobile);
    this.$siteMain = $('.site-main');

    this.$body = $(document.body);
    this.$window = $(window);
    this.requestAnimationFrameId = null;

    // Exit if Header is not sticky
    if (!this.settings.sticky_header) {
      return;
    }

    this._onBreakpointChange = this._onBreakpointChange.bind(this);
    layout.onBreakpointChange(this._onBreakpointChange);

    this._headerChecker = this._headerChecker.bind(this);
    this._checkScrolled = this._checkScrolled.bind(this);
    this._mainHeaderOffset = this._mainHeaderOffset.bind(this);

    this._init();
    this._bindEvents();
  }

  unload() {
    if (!this.settings.sticky_header) {
      return;
    }

    this.$window.off('.sticky-header');
    layout.offBreakpointChange(this._onBreakpointChange);
    window.cancelAnimationFrame(this.requestAnimationFrameId);
  }

  onSectionSelect() {
    // Reset sticky header styles if no sticky header
    if (!this.settings.sticky_header) {
      this._setSticky(false);
    }
  }

  _supportsSticky() {
    return layout.isBreakpoint('L') || layout.isBreakpoint('XL') || layout.isBreakpoint('M');
  }

  _init() {
    this._setSticky(this._supportsSticky());
    this._headerChecker();
  }

  _bindEvents() {
    this.$window.on('scroll.sticky-header', this._checkScrolled);
    this.$window.on('resize.sticky-header', this._mainHeaderOffset);
    this.$window.on('announcement-bar.sticky-header', this._mainHeaderOffset);
  }

  _onBreakpointChange() {
    this._setSticky(this._supportsSticky());
  }

  /**
   * Determine the amount to offset the body by
   *
   * @param state
   * @returns {*}
   * @private
   */
  _getStickyOffset(state) {
    if (!state) {
      return '';
    }

    const headerHeight = getHeaderHeight(this.mainHeader);
    const announcementBar = getAnnouncementBarHeight();

    return headerHeight + announcementBar;
  }

  _setScrolled(state) {
    this.$body.toggleClass(classes.header.scrolled, state);
  }

  _setSticky(state) {
    this.$body.toggleClass(classes.header.sticky, state);
    this._mainHeaderOffset();
    this._setStickyOffset(state);
  }

  _setStickyOffset(state) {
    const stickyOffset = this._getStickyOffset(state);
    this.$siteMain.css('paddingTop', stickyOffset);

    this.$window.trigger('sticky-header', {
      state,
      stickyOffset,
    });
  }

  _headerChecker() {
    // Periodically check to make sure header is offset correctly
    this.requestAnimationFrameId = window.requestAnimationFrame(this._headerChecker);

    if (this.$body.hasClass(classes.header.scrolled)) {
      return;
    }
  }

  _checkScrolled() {
    // Add border when is scrolling passed header
    const desktopThreshold = getHeaderHeight(this.mainHeader);
    const mobileThreshold = getHeaderHeight(this.mobileHeader);
    const threshold = this._supportsSticky() ? desktopThreshold : mobileThreshold;

    const isScrolled = window.pageYOffset >= threshold;
    this._setScrolled(isScrolled);
    this.$mainHeader.trigger('set-border', {
      state: (this._supportsSticky() && isScrolled),
    });
  }

  /**
   * Offset main by announcement bar when is sticky, and sticky is supported
   *
   * @private
   */
  _mainHeaderOffset() {
    const top = this._supportsSticky() ? getAnnouncementBarHeight() : '';
    this.$mainHeader.css({ top });
  }
}
