import { selectors } from '../Constants';
import ProductItem from '../components/ProductItem';

export default class SearchPage {
  constructor() {
    const searchPage = document.querySelector('.search-page');

    if (!searchPage) {
      return;
    }

    this.productItemEls = searchPage.querySelectorAll(selectors.product.item.el);

    for (let i = 0; i < this.productItemEls.length; i += 1) {
      new ProductItem(this.productItemEls[i]);
    }
  }
}
