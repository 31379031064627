import ProductLayout from '../components/ProductLayout';

export default class StaticProductPages {
  constructor(section) {
    const { el } = section;
    const { product } = section.data;
    const variantAvailable = section.data.variant_available;
    const moneyFormat = section.data.money_format;
    const textStrings = section.data.text_strings;
    const cartRedirect = section.data.cart_redirect;
    const enableHistory = true;

    this.productLayout = new ProductLayout({
      el,
      moneyFormat,
      product,
      variantAvailable,
      cartRedirect,
      textStrings,
      enableHistory,
    });
  }

  onSectionUnload() {
    this.productLayout.unload();
  }
}
