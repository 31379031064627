import MobileScrollers from '../components/MobileScrollers';

export default class DynamicBlogPosts extends MobileScrollers {
  constructor(section) {
    super(section);

    const cellAlign = this.el.querySelectorAll('.article-excerpt').length > 2
      ? 'left'
      : 'center';

    this.init('[data-blog-posts]', {
      cellAlign,
    });
  }
}
