import Masonry from 'masonry-layout';

export default class ProductMosaic {
  constructor(options = {}) {
    this.el = options.el || null;

    if (!this.el) {
      return console.warn('Unable to initiate ProductMosaic.js');
    }

    this.masonry = new Masonry(
      this.el,
      {
        columnWidth: '.product-mosaic-sizer',
        gutter: '.product-mosaic-gutter',
        itemSelector: '.product-mosaic-image-figure',
        percentPosition: true,
      },
    );
  }

  destroy() {
    this.masonry.destroy();
  }
}
