import { classes } from '../Constants';

const observer = new MutationObserver((mutations) => {
  // Access list of all 'disabled' mutations
  mutations.forEach((mutation) => {
    const target = mutation.target;

    if (target.classList.contains(classes.form.formElements.el)) {
      const parent = target.parentElement;

      if (target.disabled) {
        parent.classList.add(classes.form.formElements.disabled);
      } else {
        parent.classList.remove(classes.form.formElements.disabled);
      }
    }
  });
});

// Observe the document body, filter to only disabled events
observer.observe(
  document.body,
  {
    subtree: true,
    attributes: true,
    attributeFilter: ['disabled'],
  },
);
