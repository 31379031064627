import $ from 'jquery';
import CardsPlayer from './CardsPlayer';

export default class ProductCards {
  constructor(options) {
    this.el = options.el || null;

    if (!this.el) {
      return console.warn('Unable to initiate ProductCards.js');
    }

    this.cardsPlayer = new CardsPlayer(this.el);
  }

  switchImage(id) {
    const $images = $('[data-cards-player-card]', this.el);
    const $image = $(`[data-cards-player-card="${id}"]`, this.el);
    const index = $images.index($image);

    this.cardsPlayer.drawToCardIndex(index);
  }

  destroy() {
    this.cardsPlayer.destroy();
  }
}
