import $ from 'jquery';
import twitterFetcher from 'twitter-fetcher';
import { selectors } from '../Constants';
import MobileScrollers from '../components/MobileScrollers';

export default class DynamicTwitterFeed extends MobileScrollers {
  constructor(section) {
    super(section);
    this.$el = $(section.el);
    this.settings = section.data;
    this.flickity = null;

    this.$content = this.$el.find(selectors.twitter.container);
    this.$tweets = this.$el.find(selectors.twitter.tweet);

    this.$template = $(this.settings.template);

    const onboarding = this.settings.onboarding;
    const username = this.settings.username;
    const retweets = this.settings.retweets;
    const images = this.settings.images;
    const locale = this.settings.locale || 'en';

    this._checkBreakpoint = this._checkBreakpoint.bind(this);

    window.addEventListener('resize', this._checkBreakpoint);

    this.init(selectors.twitter.container, {
      cellSelector: selectors.twitter.tweet,
    });

    if (onboarding || !username) {
      return;
    }

    twitterFetcher.fetch({
      'profile': {
        'screenName': username,
      },
      'maxTweets': 3,
      'enableLinks': true,
      'showUser': true,
      'showTime': true,
      'dataOnly': false,
      'useEmoji': true,
      'showImages': images,
      'showRetweet': retweets,
      'lang': locale,
      'customCallback': (tweets) => this._renderTweets(tweets),
      'showInteraction': false,
    });
  }

  /**
   * Retrieve information about user who tweeted
   *
   * @param el
   * @returns {{$avatar: *, link: *, name, screenName}}
   * @private
   */
  _extractAuthor(el) {
    const $el = $(el);

    const link = $el.find('a').attr('href');
    const name = $el.find('[data-scribe="element:name"]').text();
    const screenName = $el.find('[data-scribe="element:screen_name"]').text();
    const $avatar = $el.find('img');

    return { $avatar, link, name, screenName };
  }

  /**
   * Retrieve link to tweet, and when it was tweeted
   *
   * @param el
   * @returns {{link: *, postedAt: *}}
   * @private
   */
  _extractMeta(el) {
    const $el = $(el);

    const link = $el.find('a').attr('href');
    const postedAt = $el.text();

    return { link, postedAt };
  }

  _renderTweets(tweets) {
    const tweetsLength = tweets.length;

    if (!tweets || !tweetsLength) return;

    const tweetsArray = [];

    tweets.forEach((tweet) => {
      const $tweet = $(tweet);

      const $template = this.$template.clone();
      const authorInfo = this._extractAuthor($tweet[0]);
      const meta = this._extractMeta($tweet[2]);

      const content = $tweet[1];

      // Render header
      $template
        .find('.tweet-header')
        .attr('href', authorInfo.link);

      $template
        .find('.tweet-header-image')
        .html(authorInfo.$avatar);

      $template
        .find('.tweet-header-name')
        .text(authorInfo.name);

      $template
        .find('.tweet-header-screenname')
        .text(authorInfo.screenName);

      // Render content
      $template
        .find('.tweet-content')
        .append($tweet[3] ? $tweet[3] : null)
        .append(content);

      // Render footer
      $template
        .find('.tweet-footer')
        .attr('href', meta.link)
        .find('.tweet-footer-posted')
        .text(meta.postedAt);

      tweetsArray.push($template);
    });

    // Lock content height to prevent the screen from jumping
    this.$content.css('min-height', this.$content.height());

    this.$tweets.fadeOut().promise().then(() => {
      this.destroyFlickity();
      this.$tweets.remove();

      this.$content
        .append(tweetsArray)
        .css('min-height', '');

      this.$tweets = this.$el.find('[data-tweet-content]');
      this.initFlickity();
      this.applyElementHeight('.tweet-item');
    });
  }

  _checkBreakpoint() {
    this.applyElementHeight('.tweet-item');
  }
}
