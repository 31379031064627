import $ from 'jquery';
import $script from 'scriptjs';
import RichText from './RichText';
import ProductGallery from './ProductGallery';
import ProductAtc from './ProductAtc';
import PriceManager from './PriceManager';

import { selectors } from '../Constants';

export default class ProductLayout {
  constructor(options = {}) {
    this.$window = $(window);

    const defaults = {
      el: null,
      moneyFormat: null,
      product: null,
      variantAvailable: null,
      textStrings: null,
      enableHistory: false,
      onATCSubmit: () => {},
      onATCSuccess: () => {},
    };

    this.config = $.extend({}, defaults, options);

    if (
      !this.config.el
      || !this.config.moneyFormat
      || !this.config.product
      || !this.config.textStrings
    ) {
      return console.warn('Unable to initiate ProductLayout.js');
    }

    this.product = this.config.product;

    this.mainContent = this.config.el.querySelector(selectors.product.mainContent);
    this.description = this.config.el.querySelector(selectors.product.description);
    this.$productEl = $(this.config.el).find(selectors.product.el).addBack(selectors.product.el);
    this.atc = this.config.el.querySelector(selectors.product.atc);
    this.gallery = this.config.el.querySelector(selectors.product.gallery);

    this.richText = null;
    this.productGallery = null;
    this.productMosaic = null;
    this.productAtc = null;

    this.$scripts = $('[data-scripts]');

    $script(this.$scripts.data('shopify-api-url'), () => { this._init(); });
  }

  unload() {
    if (this.richText) {
      this.richText.unload();
      this.richText = null;
    }

    if (this.productGallery) {
      this.productGallery.unload();
      this.productGallery = null;
    }

    if (this.productAtc) {
      this.productAtc.unload();
      this.productAtc = null;
    }

    if (this.stickyTick) {
      this.$window.off('scroll', this.stickyTick);
    }

    this.$window.off(`shopify-variants:switch-variant.product-layout-${this.product.id}`);
  }

  _init() {
    if (this.description) {
      this.richText = new RichText(this.description);
    }

    if (this.gallery) {
      this.productGallery = new ProductGallery({
        el: this.gallery,
        layout: this.gallery.getAttribute('data-product-gallery'),
      });
    }

    this.$window.on(
      `shopify-variants:switch-variant.product-layout-${this.product.id}`,
      (event, data) => this._switchVariant(event, data),
    );

    this.priceManager = new PriceManager(this.config.el, this.config.moneyFormat);

    if (this.atc) {
      this.productAtc = new ProductAtc({
        el: this.atc,
        container: this.$productEl[0],
        product: this.config.product,
        cartRedirect: this.config.cartRedirect,
        moneyFormat: this.config.moneyFormat,
        variantAvailable: this.config.variantAvailable,
        textStrings: this.config.textStrings,
        onATCSubmit: this.config.onATCSubmit,
        onATCSuccess: this.config.onATCSuccess,
      });
    }

    if (this.mainContent.classList.contains('product-main-content-sticky')) {
      this.stickyTick = this._stickyTick.bind(this);
      this.ticking = false;
      this.lastKnownScrollPosition = window.scrollY;
      this.currentKnownScrollPosition = window.scrollY;
      this.mainContent.style.top = '0';

      this.$window.on('scroll', this.stickyTick);
    }
  }

  _stickyTick() {
    this.lastKnownScrollPosition = this.currentKnownScrollPosition;
    this.currentKnownScrollPosition = window.scrollY;

    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this._offsetSticky(this.lastKnownScrollPosition, this.currentKnownScrollPosition);
        this.ticking = false;
      });

      this.ticking = true;
    }
  }

  _offsetSticky(lastKnownScrollPosition, currentKnownScrollPosition) {
    const offset = currentKnownScrollPosition - lastKnownScrollPosition;
    const { height } = this.mainContent.getBoundingClientRect();
    const { innerHeight } = window;
    const maxHeight = innerHeight - height;

    const topVal = parseInt(this.mainContent.style.top, 10) || 0;

    this.mainContent.style.top = `${Math.min(Math.max(topVal - offset, maxHeight), 0)}px`;
  }

  _switchVariant(event, data) {
    if (data.product !== this.product) return;

    const { variant } = data;

    // Update gallery image
    if (this.gallery && this.productGallery && variant.featured_image) {
      this.productGallery.switchImage(variant.featured_image.id);
    }

    // Update Variant information
    this._updatePrice(variant);

    if (this.config.enableHistory) {
      const url = `${this.config.product.handle}?${$.param({ variant: variant.id })}`;
      history.replaceState({}, 'variant', url);
    }
  }

  _updatePrice(variant) {
    const $compareAtPrice = $(selectors.price.compareAt, this.config.el);
    const $price = $(selectors.price.price, this.config.el);

    const $compareAtPriceMoney = $('.money', $compareAtPrice);
    const $priceMoney = $('.money', $price);

    const hasComparePrice = (
      !!variant.compare_at_price && variant.compare_at_price > variant.price
    );

    // Update compare at price
    $compareAtPrice.toggleClass('visible', hasComparePrice);
    $compareAtPriceMoney.each( (i, el) => {
      this.priceManager.updatePrice(el, variant.compare_at_price);
    });

    // Update price
    $priceMoney.each( (i, el) => {
      this.priceManager.updatePrice(el, variant.price);
    });
  }
}
