import $ from 'jquery';
import $script from 'scriptjs';
import { selectors, classes, events } from '../../Constants';
import CartRequests from './CartRequests';
import CartItem from './CartItem';
import PriceManager from '../PriceManager';

// TODO: Handle cart/vs mini-cart differences
// TODO: Additional cart buttons

export default class Cart {
  constructor(el, options = {}) {
    this.el = el;
    this.$el = $(el);
    this.moneyFormat = options.moneyFormat;
    this.shipping = options.shipping;
    this.$window = $(window);
    this.isInline = this.$el.hasClass(classes.cart.inline);
    this.isStatic = this.$el.hasClass(classes.cart.static);
    this.hasShipping = $(selectors.cart.shipping.el, el).length;

    this.cartRequests = new CartRequests();

    this.onExpandClick = this._onExpandClick.bind(this);
    this.onOtherItemsClick = this._onOtherItemsClick.bind(this);
    this.onNoteChange = this._onNoteChange.bind(this);
    this.onLoadingStart = this._onLoadingStart.bind(this);
    this.onLoadingFinish = this._onLoadingFinish.bind(this);
    this.onShippingSubmit = this._onShippingSubmit.bind(this);
    this.renderShippingResponse = this._renderShippingResponse.bind(this);

    this.$scripts = $('[data-scripts]');

    $script(this.$scripts.data('shopify-api-url'), () => {
      if (this.hasShipping) {
        Shopify.onError = this._onError.bind(this);

        $script(this.$scripts.data('shopify-countries'), () => {
          $script(this.$scripts.data('shopify-common'), () => {
            $script(this.$scripts.data('shopify-jquery-cart'), () => {
              this._initShippingCalc();
            });
          });
        });
      }
    });


    this._init();
  }

  removeCartItem(cartItem) {
    const index = this.items.indexOf(cartItem);

    this.items.splice(index, 1);
  }

  unload() {
    if (this.note) {
      this.note.removeEventListener('change', this.onNoteChange);
    }

    this.$expandButtons.off('click', this.onExpandClick);
    this.$window.off(events.cart.loadingStart, this.onLoadingStart);
    this.$window.off(events.cart.loadingFinish, this.onLoadingFinish);

    for (let i = 0; i < this.items.length; i += 1) {
      this.items[i].unload();
    }
  }

  _init() {
    this.$expandButtons = $(selectors.cart.expand, this.el);
    this.note = this.el.querySelector(selectors.cart.note);
    this.items = this._createItems();
    this.hasItems = this.items.length;
    this.$otherItemsButton = $(selectors.cart.otherItemsButton, this.el);
    this.totals = this.el.querySelector(selectors.cart.totals);
    this.subtotal = this.el.querySelector(selectors.cart.subtotal);

    this.priceManager = new PriceManager(this.totals, this.moneyFormat);
    this.priceManager.updateAll();

    if (this.note) {
      this.note.addEventListener('change', this.onNoteChange);
    }

    this.$expandButtons.on('click', this.onExpandClick);
    this.$otherItemsButton.on('click', this.onOtherItemsClick);
    this.$window.on(events.cart.loadingStart, this.onLoadingStart);
    this.$window.on(events.cart.loadingFinish, this.onLoadingFinish);

    this.$el.removeClass(classes.cart.expanded);
    this.$el.toggleClass(classes.cart.empty, !this.hasItems);
  }

  _initShippingCalc() {
    this.$shippingResponse = $(selectors.cart.shipping.response, this.el);
    this.$shippingResponseMessage = $(selectors.cart.shipping.message, this.el);
    this.$shippingResponseRates = $(selectors.cart.shipping.rates, this.el);
    this.$shippingSubmit = $(selectors.cart.shipping.submit, this.el);

    Shopify.Cart.ShippingCalculator.show({
      submitButton: this.shipping.submitText,
      submitButtonDisabled: this.shipping.submittingText,
      customerIsLoggedIn: this.shipping.customerIsLoggedIn,
      moneyFormat: this.moneyFormat,
    });

    this.$shippingSubmit.on('click', this.onShippingSubmit);
  }

  _createItems() {
    const itemEls = this.el.querySelectorAll(selectors.cart.item.el);
    const items = [];

    for (let i = 0; i < itemEls.length; i += 1) {
      items.push(
        new CartItem(
          itemEls[i],
          this,
          { moneyFormat: this.moneyFormat },
        ),
      );
    }

    return items;
  }

  _onExpandClick(event) {
    const $target = $(event.currentTarget);
    const $expandable = $($target.siblings(selectors.cart.expandable));

    $target.toggleClass(classes.cart.expandable.expanded);
    $expandable.slideToggle();
  }

  _onOtherItemsClick(event) {
    if (event) {
      event.preventDefault();
    }

    this.$otherItemsButton.remove();
    this.$el.addClass(classes.cart.expanded);

    const $items = $('[data-cart-item]', this.el);

    $items.addClass(classes.cart.item.inlineVisible);

    $(window).trigger(events.cart.inlineVisible);
  }

  _onNoteChange(event) {
    const $target = $(event.currentTarget);
    const note = $.trim($target.val());

    this.cartRequests.submitNoteRequest(note);
  }

  _onLoadingStart(event, data) {
    const {
      affectsTotal,
      reload,
      removing,
    } = data;

    $(selectors.cart.submit, this.$el).prop('disabled', true);

    this.$el.addClass(classes.cart.loading);

    if (affectsTotal) {
      this.$el.addClass(classes.cart.loadingAffectsTotal);
    }

    if (reload) {
      this.$el.addClass(classes.cart.reloading);
      this.$el.trigger(events.cart.reloadStart);
    }

    if (this.items.length === removing) {
      this.$el.css('min-height', this.$el.height());
      this.$el.addClass(classes.cart.reloading);
    }
  }

  _onLoadingFinish(event, data) {
    const {
      response: cartResponse,
      reload,
      removing,
      subtotal,
      recentItem,
      error,
    } = data;

    this.priceManager.updatePrice(this.subtotal, subtotal);
    this.priceManager.updateAll();

    $(selectors.cart.submit, this.$el).prop('disabled', false);

    this.$el.removeClass(classes.cart.loading);
    this.$el.removeClass(classes.cart.loadingAffectsTotal);

    let view = 'mini-cart';

    if (this.isStatic) {
      view = 'static';
    } else if (this.isInline) {
      view = 'inline';
    }

    if (reload) {
      this.unload();

      $.ajax({
        url: `${document.location.origin}/cart`,
        data: {
          view,
        },
        dataType: 'html',
        method: 'GET',
      }).done((response) => {
        const $response = $(response);
        const $form = $response.find(selectors.cart.form);

        $form.replaceAll(this.$el.find(selectors.cart.form));

        this._init();

        if (this.isInline && recentItem) {
          const $recentItemEls = $(`[data-cart-item][data-variant-id="${recentItem}"]`, this.el);

          if (this.$otherItemsButton.length) {
            // Update "view other items" text, in case there are more than 1 recent items
            const otherItemsTemplate = this.$otherItemsButton[0].dataset.cartOtheritemsTemplate;
            const otherItemsTemplateOne = this.$otherItemsButton[0].dataset.cartOtheritemsTemplateOne;

            const otherItemsCount = cartResponse.items.length - $recentItemEls.length;

            if (otherItemsCount > 1) {
              this.$otherItemsButton.text(otherItemsTemplate.replace('**count**', otherItemsCount));
            } else if (otherItemsCount === 1) {
              this.$otherItemsButton.text(otherItemsTemplateOne);
            } else {
              this.$otherItemsButton.hide();
            }
          }

          this.$el.find(selectors.cart.atcSuccess).show();

          $recentItemEls.addClass(classes.cart.item.inlineRecentlyAdded);
        }

        this.$el.removeClass(classes.cart.reloading);
        this.$el.trigger(events.cart.reloadFinish, { error });
      });
    }

    if (this.items.length === removing) {
      this.$el.css('min-height', '');
      this.$el.removeClass(classes.cart.reloading);
      this.$el.addClass(classes.cart.empty);
    }
  }

  _onShippingSubmit(event) {
    event.preventDefault();

    this._disableShippingButton();

    const shippingAddress = {};
    shippingAddress.country = $('#address_country').val() || '';
    shippingAddress.province = $('#address_province').val() || '';
    shippingAddress.zip = $('#address_zip').val() || '';

    // Creates an ajax request which returns shipping information
    Shopify.getCartShippingRatesForDestination(shippingAddress, this.renderShippingResponse);
  }

  /**
   * Handle Errors returned from Shopify
   *
   * @param xhr
   * @private
   */
  _onError(xhr = null) {
    if (!xhr) {
      return;
    }

    const errors = $.parseJSON(xhr.responseText);

    if (errors.zip.length > 0) {
      // Hide the response so that it can be populated smoothly
      this._hideShippingResponse();

      // Empty out contents
      this.$shippingResponseMessage.empty();
      this.$shippingResponseRates.empty();

      if (errors.zip[0].indexOf('is not valid') !== -1 || errors.zip[0].indexOf('can\'t be blank') !== -1) {
        this.$shippingResponseMessage.html(`${this.shipping.postalcode} ${errors.zip}`);
      }

      this._showShippingResponse();
      this._enableShippingButton();
    }
  }

  _renderShippingResponse(response, shippingAddress) {
    const addressBase = [];

    if (shippingAddress.zip.length) {
      addressBase.push(shippingAddress.zip.trim());
    }

    if (shippingAddress.province.length) {
      addressBase.push(shippingAddress.province);
    }

    if (shippingAddress.country.length) {
      addressBase.push(shippingAddress.country);
    }

    const address = addressBase.join(', ');

    // Hide the response so that it can be populated smoothly
    this._hideShippingResponse();

    // Empty out contents
    this.$shippingResponseMessage.empty();
    this.$shippingResponseRates.empty();

    let responseText = '';

    if (response.length > 1) {
      const firstRate = Shopify.Cart.ShippingCalculator.formatRate(response[0].price);
      responseText = this.shipping.multiple_rates
        .replace('*address*', address)
        .replace('*number_of_rates*', response.length)
        .replace('*rate*', `<span class="money">${firstRate}</span>`);
    } else if (response.length === 1) {
      responseText = this.shipping.one_rate.replace('*address*', address);
    } else {
      responseText = this.shipping.no_rates;
    }

    this.$shippingResponseMessage.html(responseText);

    for (let i = 0; i < response.length; i++) {
      const rate = response[i];
      const price = Shopify.Cart.ShippingCalculator.formatRate(rate.price);
      const rateValue = this.shipping.rate_value
        .replace('*rate_title*', rate.name)
        .replace('*rate*', `<span class="money">${price}</span>`);

      this.$shippingResponseRates.append(`<li>${rateValue}</li>`);
    }

    // Reset the calculating button so it can be used again
    this._enableShippingButton();

    // Show the response
    this._showShippingResponse();
  }

  _enableShippingButton() {
    this.$shippingSubmit
      .text(this.shipping.submit_text)
      .attr('disabled', false);
  }

  _disableShippingButton() {
    this.$shippingSubmit
      .text(this.shipping.submitting_text)
      .attr('disabled', true);
  }

  _showShippingResponse() {
    this.$shippingResponse.addClass(classes.cart.shipping.responseVisible);
  }

  _hideShippingResponse() {
    this.$shippingResponse.removeClass(classes.cart.shipping.responseVisible);
  }
}
