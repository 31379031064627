import $ from 'jquery';
import { events } from '../../Constants';
import { BannerManager, Type, Priority } from '../../components/BannerManager';

let instance = null;

export default class CartRequests {
  constructor() {
    if (!instance) {
      instance = this;
    } else {
      return instance;
    }

    this.$window = $(window);
    this.handlerHelpers = {};
    this.postRequests = this._postRequests.bind(this);
    this.handleResponse = this._handleResponse.bind(this);
    this.handleError = this._handleError.bind(this);

    this.additionalHandlers = {
      onResponse: null,
      onError: null,
    };

    this.lastRequestTimestamp = Math.floor(Date.now());
    this.postAfterLastRequestMillis = 1000;

    this.noteRequest = null;
    this.atcItemRequest = null;
    this.updateItemRequests = [];
    this.inProgressItemRequests = [];

    this.triggerCartReload = false;
    this.recentItem = null;
    this.lockSubmission = false;

    this.bannerManager = new BannerManager();

    this.interval = setInterval(this.postRequests, this.postAfterLastRequestMillis);

    return instance;
  }

  submitItemRequest(attributes, triggerCartReload = false, atc = false) {
    let itemRequest = this._findExistingItemRequest(attributes.id, atc);

    if (itemRequest) {
      itemRequest.quantity = attributes.quantity;
    } else {
      itemRequest = attributes;
    }

    if (atc === true) {
      this.atcItemRequest = itemRequest;
    } else {
      this.updateItemRequests.push(itemRequest);
    }

    itemRequest.quantity = Math.max(0, itemRequest.quantity);

    this.itemsRemoving = this._countItemsRemoving();
    this.triggerCartReload = this.triggerCartReload || triggerCartReload;
    this.recentItem = triggerCartReload ? attributes.id : this.recentItem;
    this.lastRequestTimestamp = Math.floor(Date.now());

    if (this.updateItemRequests.length > 0){
      this.inProgressItemRequests.push(...this.updateItemRequests);
    }

    if (this.atcItemRequest !== null){
      this.inProgressItemRequests.push(this.atcItemRequest);
    }

    this._triggerStartLoadingEvents();
  }

  submitNoteRequest(note) {
    this.noteRequest = note;
    this.lastRequestTimestamp = Math.floor(Date.now());
    this._triggerStartLoadingEvents();
  }

  unload() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  setHandlers(responseHandlers) {
    instance.handlerHelpers = $.extend({}, instance.additionalHandlers, responseHandlers);
  }

  restoreHandlers() {
    this.handlerHelpers = this.additionalHandlers;
  }

  _countItemsRemoving() {
    return this.updateItemRequests.reduce((removing, itemRequest) => {
      const quantity = itemRequest.quantity;

      return quantity === 0 ? removing + 1 : removing;
    }, 0);
  }

  _triggerStartLoadingEvents() {
    const itemRequests = this.inProgressItemRequests;

    itemRequests.forEach((itemRequest) => {
      const request = itemRequest;

      this.$window.trigger(
        events.cart.item.loadingStart,
        { request },
      );
    });

    if (this.note) {
      const request = this.note;

      this.$window.trigger(
        events.cart.note.loadingStart,
        { request },
      );
    }

    this.$window.trigger(
      events.cart.loadingStart,
      {
        affectsTotal: itemRequests.length,
        reload: this.triggerCartReload,
        removing: this.itemsRemoving,
      },
    );
  }

  _triggerFinishLoadingEvent(cartResponse, itemResponses, error = false) {
    itemResponses.forEach((itemResponse) => {
      const response = itemResponse;

      this.$window.trigger(
        events.cart.item.loadingFinish,
        { response },
      );
    });

    this.$window.trigger(events.cart.note.loadingFinish);

    this.$window.trigger(
      events.cart.loadingFinish,
      {
        response: cartResponse,
        reload: this.triggerCartReload,
        removing: this.itemsRemoving,
        subtotal: cartResponse.original_total_price,
        recentItem: this.recentItem,
        error,
      },
    );

    this.triggerCartReload = false;
    this.itemsRemoving = 0;
  }

  _parseItemResponses(cartResponse) {
    const itemResponses = [];

    for (let i = 0; i < this.inProgressItemRequests.length; i += 1) {
      const itemRequest = this.inProgressItemRequests[i];
      const itemResponse = {
        variant: itemRequest.id,
        quantity: 0,
        total: 0,
      };

      for (let j = 0; j < cartResponse.items.length; j += 1) {
        const item = cartResponse.items[j];

        if (itemRequest.id === item.variant_id) {
          itemResponse.variant = item.variant_id;
          itemResponse.quantity = item.quantity;
          itemResponse.total = item.original_line_price;
        }
      }

      itemResponses.push(itemResponse);
    }

    return itemResponses;
  }

  _handleResponse(cartResponse) {
    const itemResponses = this._parseItemResponses(cartResponse);

    this._triggerFinishLoadingEvent(cartResponse, itemResponses);

    this.inProgressItemRequests = [];
    this.lockSubmission = false;

    if (this.handlerHelpers.onResponse) {
      this.handlerHelpers.onResponse(cartResponse);
    }
  }

  _handleError(error) {
    const get = $.get({
      url: '/cart.js',
      success: null,
      dataType: 'json',
    });

    get.done((cartResponse) => {
      const itemResponses = this._parseItemResponses(cartResponse);

      if (this.handlerHelpers.onError) {
        this.handlerHelpers.onError(error);
      } else {
        this.bannerManager.add(
          error.responseJSON.description,
          Type.error,
          Priority.high,
          { autoDismiss: 5 },
        );
      }

      this._triggerFinishLoadingEvent(cartResponse, itemResponses, true);

      this.inProgressItemRequests = [];
      this.lockSubmission = false;
    });
  }

  _shouldPostRequests() {
    return (
      !this.lockSubmission
      && (
        this.atcItemRequest !== null
        || this.updateItemRequests.length > 0
        || this.noteRequest !== null
      ) && (
        Math.floor(Date.now())
        - this.lastRequestTimestamp
        >= this.postAfterLastRequestMillis
      )
    );
  }

  _postRequests() {
    if (!this._shouldPostRequests()) {
      return;
    }

    let updateRequest = false;
    let atcRequest = this.atcItemRequest;

    if (this.updateItemRequests.length > 0 || this.noteRequest !== null) {
      updateRequest = {
        note: this.noteRequest,
        updates: {},
      };

      this.updateItemRequests.forEach((itemRequest) => {
        updateRequest.updates[itemRequest.id] = itemRequest.quantity;
      });
    }

    this.lockSubmission = true;
    this.noteRequest = null;
    this.updateItemRequests = [];
    this.atcItemRequest = null;

    let promises = [];

    if (updateRequest) {
      const postUpdate = $.post({
        url: '/cart/update.js',
        data: updateRequest,
        dataType: 'json'
      });

      promises.push(postUpdate);
    }

    if (atcRequest !== null){
      const postAtc = $.post({
        url: '/cart/add.js',
        data: atcRequest,
        dataType: 'json'
      });

      promises.push(postAtc);
    }

    $.when(...promises)
      .then(() => {
        const cartData = $.get({
          url: '/cart.js',
          dataType: 'json',
        });

        cartData
          .done(this.handleResponse)
          .fail(this.handleError);
      }).catch(this.handleError);
  }

  _findExistingItemRequest(variant, atc = false) {
    let itemRequest = null;

    if (atc) {
      if (this.atcItemRequest !== null
        && this.atcItemRequest.id === variant) {
        itemRequest = this.atcItemRequest;
      }
    } else {
      this.updateItemRequests.forEach((iRequest) => {
        if (iRequest.id === variant) {
          itemRequest = iRequest;
        }
      });
    }

    return itemRequest;
  }
}
