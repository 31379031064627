import FeaturesList from './FeaturesList';
import Gallery from './Gallery';
import VideoWithText from './VideoWithText';
import ImageWithTextOverlay from './ImageWithTextOverlay';
import ShareBlock from './ShareBlock';
import Testimonials from './Testimonials';

export default class StaticStory {
  constructor(section) {
    this.el = section.el;
    this.blocks = this.el.querySelectorAll('[data-story-block]');
    this.instances = {};

    this.instanceTypes = {
      'features-list': FeaturesList,
      'gallery': Gallery,
      'video-with-text': VideoWithText,
      'image-with-text-overlay': ImageWithTextOverlay,
      'share': ShareBlock,
      'testimonials': Testimonials,
    };

    if (this.blocks) {
      this._initBlocks();
    }
  }

  onSectionUnload() {
    Object.keys(this.instances).forEach((id) => {
      if (this.instances[id] && this.instances[id].onSectionUnload) {
        // Each Block in Story is treated as a Section
        this.instances[id].onSectionUnload();
      }
    });

    this.instances = {};
  }

  /**
   * Block information
   * - block.el: Used for compatibility with Section events
   * - block.type: Used to map to appropriate JS Class
   * - block.id: For possible future usage for block event handling
   *
   * @param block
   * @returns {{el: *, type: (string|*), id: (string|*)}}
   * @private
   */
  _getBlockInfo(block) {
    return {
      el: block,
      type: block.getAttribute('data-story-block'),
      id: block.getAttribute('data-story-id'),
    };
  }

  _initBlocks() {
    for (let i = 0; i < this.blocks.length; i++) {
      const block = this.blocks[i];
      const blockInfo = this._getBlockInfo(block);

      if (this.instanceTypes[blockInfo.type]) {
        this.instances[blockInfo.id] = new this.instanceTypes[blockInfo.type](blockInfo);
      }
    }
  }
}
