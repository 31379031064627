import $ from 'jquery';
import debounce from 'just-debounce';

export default class ImageWithTextOverlay {
  constructor(container) {
    this.el = container.el.querySelector('[data-image-block]');
    this.image = this.el.querySelector('[data-image-block-image]');
    this.$image = $(this.image);
    this.content = this.el.querySelector('[data-image-block-content]');

    this.onResize = debounce(this._checkHeights, 15).bind(this);
    window.addEventListener('resize', this.onResize);

    this._checkHeights();
  }

  onSectionUnload() {
    window.removeEventListener('resize', this.onResize);
  }

  _checkHeights() {
    const imageHeight = this.image.getBoundingClientRect().height;
    const contentHeight = this.content.getBoundingClientRect().height;

    if (contentHeight === imageHeight) {
      return;
    }

    if (contentHeight > imageHeight) {
      this.$image.css('min-height', contentHeight);
    } else {
      this.$image.css('min-height', '');
    }
  }
}

